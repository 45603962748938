.lightBox-deActive {
  height: 0 !important;
  width: 0 !important;
}
.lightBox-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.lightBox {
  width: 100vw;
  height: 100%;
  position: relative;
}
.lightBox-img {
  max-width: fit-content;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transition: 500ms;
}

.lightBox-slider {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightBox .slick-slide div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.lightBoxThumb {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  gap: 5px;
  bottom: 0;
  z-index: 9999;
}

.lightBoxThumb-img {
  width: 100px;
  height: 100px;
  opacity: 0.5;
  transition: 500ms;
}

.lightBox-arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 10px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  transition: 500ms;
  z-index: 9;
}

.closeLightBox {
  height: 40px;
  width: 40px;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  transition: 500ms;
  cursor: pointer;
  z-index: 9;
}

.lightBox-arrow:hover,
.closeLightBox:hover {
  opacity: 1;
}

.lightBoxSlide-Button,
.LBCloseButton {
  fill: white;
}

.lightBox-arrow-left {
  left: 10px;
}

.lightBox-arrow-right {
  right: 10px;
}

.lightBox-img-active {
  opacity: 1;
  z-index: 1;
}
