/* =====================================
Template Name: DashBD Dashboard
Author Name: QoumodoTheme
Version:	1.0.0
========================================*/
/*======================================
[ CSS Sections ]
* Header & Sidebar CSS
* Widgets CSS
	+ Progress CSS
	+ Chart JS CSS
	+ Tables CSS
	+ Calendar CSS
	+ Maps CSS
	+ Banner & Cards CSS
	+ Notifications CSS
	+ Popups CSS
	+ Pagination CSS
	+ Tabs
	+ Accordion
* Chat & Email CSS
* To-do List CSS
* Profiles CSS
* Profile Settings CSS
* Pages CSS
	+ Team CSS
	+ Faq CSS
	+ Pricing CSS
	+ Coming Soon CSS
	+ Gallery CSS
* Forms CSS
* Utilities CSS
========================================*/

/*======================================
    Header & Sidebar CSS
========================================*/
/* Admin Menu */

.admin-menu {
  background: #fff;
  height: 100%;
  scrollbar-width: none;
  overflow: scroll;
  transition: all 0.3s ease;
  border-right: 1px solid #e8edff;
}
.admin-menu__one {
  padding-bottom: 25px;
}
.admin-menu__two {
  padding-bottom: 40px;
}
.crancy-sidebar-padding {
  padding-left: 40px;
  padding-right: 40px;
}
.admin-menu__title {
  margin-bottom: 10px;
  color: #6d6e70;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #edf2f7;
  padding-bottom: 5px;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  border-bottom: 1px solid #e8edff;
}
.logo a {
  position: relative;
}
.crancy-logo__main--dark,
.crancy-logo__main--small,
.crancy-logo__main--small--dark {
  display: none;
  transform: scale(0);
}
.crancy-smenu.crancy-close .logo .crancy-logo__main {
  display: none;
}

#crancy-dark-light.active .crancy-logo__main,
#crancy-dark-light.active .crancy-logo__main--small {
  display: none;
}

#crancy-dark-light.active .crancy-logo__main--dark {
  display: block;
  transform: scale(1);
}
#crancy-dark-light.active .crancy-smenu.crancy-close .crancy-logo__main--dark {
  display: none;
}
#crancy-dark-light.active
  .crancy-smenu.crancy-close
  .crancy-logo__main--small--dark {
  display: block;
  transform: scale(1);
}

.author {
  display: flex;
  border-bottom: 1px solid rgba(243, 245, 249, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.author .author-title {
  margin-left: 15px;
  margin-top: 10px;
}
.author .author-title h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}
.author .author-title p {
  font-size: 14px;
  line-height: 18px;
  color: #ffffff82;
}
.menu-bar li {
  position: relative;
  transition: all 0.3s ease;
}
.menu-bar__name {
  position: relative;
  top: 2px;
}
.menu-bar li .menu-bar__text i {
  transition: all 0.3s ease;
}
.menu-bar li:hover .menu-bar__name,
.menu-bar li.active .menu-bar__name {
  color: #0a82fd;
}
.menu-bar li.active-icon:before {
  opacity: 1;
  visibility: visible;
}
.menu-bar li.show-dropdown:before {
  content: "\f106";
}
.menu-bar li a {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #0a82fd;
  padding: 14px 0px;
  display: inline-block;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-bar li a.collapsed {
  color: #2f3032;
}
.menu-bar li ul li a {
  color: #fff;
  padding: 10px 0px;
  font-size: 18px;
}
.menu-bar li ul li a span {
  color: #9aa2b1 !important;
}
.menu-bar li ul li a:hover span,
.menu-bar li ul li a:hover {
  color: #0a82fd !important;
}
.crancy__dropdown ul li a i {
  margin-right: 5px;
  font-size: 14px;
}
.menu-bar li ul li a img {
  max-width: 17px;
}
.menu-bar li ul {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu-bar li .crancy__dropdown {
  padding-left: 15px;
  border-left: 1px solid #e4f2ffb5;
  margin-left: 40px;
}
.menu-bar li.show-dropdown ul {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.crancy-admin-space {
  margin-left: 40px;
  margin-right: 40px;
}
.menu-bar__text {
  position: relative;
  width: 100%;
}
.crancy-menu-group {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 70px;
  justify-content: center;
}
.menu-bar__count {
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  padding: 0;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.menu-bar__text .crancy-menu-icon {
  margin-right: 1px;
  padding: 0;
  border-radius: 100%;
  display: inline-block;
  transition: all 0.3s ease;
  color: #2f3032;
  min-width: 30px;
}
.menu-bar__text .crancy-menu-icon .crancy-svg-icon {
  width: 21px;
  height: 21px;
  stroke: currentColor;
}
.crancy-dashboard-menu .collapsed .crancy-menu-icon .crancy-svg-icon {
  color: #2f3032;
}
.menu-bar li .crancy-menu-icon .crancy-svg-icon,
.menu-bar li:hover .collapsed .crancy-menu-icon .crancy-svg-icon,
.menu-bar li.active .crancy-menu-icon .crancy-svg-icon {
  color: #0a82fd;
}
.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v2 .crancy-svg-icon {
  stroke: initial;
  fill: currentColor;
}

.crancy__dropdown ul li a {
  padding: 10px 0;
}
.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v3 .crancy-svg-icon {
  width: 13px;
  height: 14px;
}

.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v5 .crancy-svg-icon {
  width: 15px;
  height: 15px;
}
.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v7 .crancy-svg-icon,
.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v8 .crancy-svg-icon {
  width: 14px;
  height: 13px;
}
.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v9 .crancy-svg-icon {
  width: 19px;
  height: 19px;
}
.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v10 .crancy-svg-icon {
  width: 15px;
  height: 20px;
}
.menu-bar__text .crancy-menu-icon.crancy-svg-icon__v11 .crancy-svg-icon {
  width: 14px;
  height: 16px;
}
.menu-bar img {
  margin-right: 10px;
}
.logout-button {
  margin-top: 40px;
}
.logout-button .crancy-btn {
  padding: 7px 14px;
  background: #0a82fd;
  color: #fff;
  display: flex;
  gap: 10px;
  font-size: 20px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}
.logo-button__icon svg {
  stroke: #0a82fd;
}

.logout-button__text {
  display: flex;
  align-items: center;
  gap: 10px;
}
.logout-button .crancy-btn:hover {
  background-color: #101324;
}
.logout-button .crancy-btn span {
  font-size: 18px;
  font-weight: 700;
}

.crancy-support-card {
  background-color: #0a82fd;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px 20px;
  border-radius: 10px;
  text-align: center;
  margin-top: 75px;
}
.crancy-support-card__sing {
  position: relative;
  top: -25px;
  margin-bottom: -25px;
}
.crancy-support-card__title {
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  padding-top: 20px;
}
.crancy-support-card__text {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 20px;
}

.logo-button__icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  text-align: center;
  border-radius: 100%;
  text-align: center;
  border: 1px solid transparent;
}
.crancy-header__inner {
  display: flex;
  align-items: center;
}
.crancy-header__middle {
  justify-content: space-between;
  gap: 25px;
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 370px;
  padding-right: 40px;
}
.crancy-header__left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.crancy-header__form-inner {
  width: 400px;
  position: relative;
}
.crancy-header__form-inner input {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 60px 0 20px;
  border: none;
  background: #f5faff;
  border-radius: 4px;
  border: none !important;
  line-height: initial !important;
  min-height: initial !important;
}
.search-btn {
  position: absolute;
  padding: 0;
  background: #0a82fd;
  border: none;
  width: 52px;
  height: 92%;
  top: 4%;
  right: 1%;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.crancy-header__group {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: right;
}
.crancy-header__right {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: end;
}
.crancy-language__dropdown {
  position: absolute;
  opacity: 1;
  visibility: visible;
  top: 18px;
  width: 160px;
}
.crancy-language {
  position: relative;
}
.crancy-balance.crancy-language__list {
  /* display: flex; */
  flex-direction: column;
  gap: 8px;
  top: 16px !important;
  width: 200px;
  left: initial;
  margin: 0;
  transform: translateY(50px);
}

.crancy-language img {
  width: 26px !important;
  border-radius: 100px;
  height: 26px !important;
}
.crancy-language__list a {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 15px;
}
.crancy-language__list span {
  font-size: 16px;
}
.crancy-language__list a:hover {
  color: #0a82fd;
}
.crancy-language a {
  color: #2f3032;
  display: flex;
  align-items: center;
  gap: 10px;
}

.crancy-language:hover .crancy-balance {
  opacity: 1;
  visibility: visible;
}

.crancy-header__svg--icon {
  fill: #2f3032;
}
.crancy-header__group button {
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
}
.crancy-header__group button:hover {
  transform: scale(0.9);
}
.crancy-header__amount {
  display: flex;
  background: #0a82fd;
  color: #fff;
  border-radius: 58px;
  align-items: center;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 2px;
  height: 48px;
  gap: 20px;
}
.crancy-header__author {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
}
.crancy-header__alarm {
  background: #fff;
  text-align: center;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}
.crancy-header__alarm i {
  font-size: 20px;
}
.crancy-header__count {
  position: absolute;
  right: -6px;
  top: -6px;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 100%;
  color: #fff;
  line-height: 16px;
  font-size: 14px;
}
.crancy-amount__icon {
  font-size: 30px;
}
.crancy-amount__digit {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}
.crancy-header__plus {
  color: #fff;
  width: 42px;
}
.crancy-header__plus a {
  width: 38px;
  text-align: center;
  border-radius: 100%;
}

.crancy-header__author-title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  font-size: 20px;
}
.crancy-header__author-title img {
  max-width: 17px;
}
.crancy-header__author-text {
  margin: 0;
  line-height: initial;
}
.crancy-header__author-text.v1 a {
  letter-spacing: initial;
}
.crancy-header__author-img {
  max-width: 62px;
}
.crancy-header__author-img img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  min-width: 44px !important;
  min-height: 44px !important;
}
.crancy-header__author-text a {
  color: #9aa2b1;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  letter-spacing: 0.025em;
}
.crancy-header {
  position: sticky;
  top: 0;
  align-items: center;
  z-index: 5000;
  background: #fff;
}
/* Chat Message */
.crancy-balance_list.crancy-chatbox__list li {
  padding-left: 0;
}
.crancy-balance_list.crancy-chatbox__list .crancy-chatbox__author-title {
  font-size: 16px;
  margin-bottom: 8px;
}
.crancy-balance_list.crancy-chatbox__list .crancy-chatbox__author-desc {
  font-size: 14px;
}

/* Balance Hover */
.crancy-header__amount {
  position: relative;
}
.crancy-balance {
  position: absolute;
  left: 50%;
  top: 70px;
  background: #fff;
  z-index: 100;
  width: 400px;
  padding: 30px 0;
  border-radius: 12px;
  box-shadow: 19px 11px 127px 0 #00000030;
  margin-left: -200px;
  /* transform: translateY(50px); */
  display: none;
  transition: all 0.4s;
}
.crancy-balance::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -30px;
  width: 30px;
  height: 30px;
  border-bottom: 30px solid #fff;
  border-left: 24px solid transparent;
  border-right: 25px solid transparent;
  margin-left: -24px;
}
.crancy-balance__title {
  border-bottom: 1px solid #e4f2ff;
  padding: 0 25px 20px;
  font-size: 18px;
  color: #2f3032;
  font-weight: 500;
}
.crancy-header__amount:hover .crancy-balance {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.crancy-balance_list {
  padding: 0 25px;
  display: flex;
  flex-direction: column;
}
.crancy-balance_list li {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e4f2ff;
}
.crancy-balance_list li:last-child {
  border: none;
  padding-bottom: 0;
  margin: 0;
}
.crancy-balance-info {
  display: flex;
  gap: 18px;
  align-items: center;
}
.crancy-balance__img {
  border-radius: 100%;
  padding: 0;
  text-align: center;
  line-height: 52px;
  display: block;
  min-width: 52px;
  min-height: 52px;
  width: 52px;
  height: 52px;
}
.crancy-balance__img img {
  max-width: 25px;
}
.crancy-balance-amount {
  font-size: 20px;
  margin: 0;
}
.crancy-balance-usd {
  font-size: 16px;
  font-weight: 400;
  color: #838b95;
  padding-top: 2px;
  display: block;
}
.crancy-balance-name {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}
.crancy-header__group .crancy-balance-name {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.crancy-header__group .crancy-balance__sell-all {
  line-height: initial;
}
.crancy-balance-amount {
  font-size: 20px;
  margin: 0;
  text-align: right;
  min-width: 115px;
}
.crancy-balance__button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.crancy-balance__button a {
  font-size: 16px;
}
.crancy-balance__button a:hover {
  text-decoration: underline;
}
.crancy-btn.btn-balance {
  padding: 12px 12px;
}
.crancy-balance-name:hover,
.crancy-balance-name a:hover {
  color: #0a82fd;
}
/* Notification Hover */
.crancy-header__alarm:hover .crancy-balance {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.crancy-balance.crancy-alarm__hover {
  text-align: left;
  left: initial;
  right: -90px;
}
.crancy-balance.crancy-alarm__hover::before {
  right: 75px;
  left: initial;
}
.crancy-alarm__hover .crancy-balance-info {
  align-items: flex-start;
}

.crancy-alarm__text {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  line-height: initial;
}
.crancy__notification__title {
  font-size: 24px;
}
.crancy-notifications {
  background: #fff;
  padding: 45px;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
}
.crancy-chatbox__list.crancy-chatbox__list__header .crancy-chatbox__inner {
  padding: 0;
  margin: 0;
  border-bottom: 0;
}
.crancy-chatbox__list.crancy-chatbox__list__header li {
  background: none !important;
  padding-right: 0;
}
.crancy-chatbox__list.crancy-chatbox__list__header
  li
  .crancy-chatbox__author-desc {
  line-height: initial;
  padding: 0;
}

.crancy-chatbox__list.crancy-chatbox__list__header .crancy-chatbox__time {
  line-height: 24px;
}

/* crancy Profile Hover */
.crancy-header__author:hover .crancy-profile__hover {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.crancy-balance.crancy-profile__hover {
  right: -26px;
  top: 80px;
  max-width: 295px;
  margin-left: 0;
  left: initial;
}
.crancy-profile__hover .crancy-balance__img {
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  line-height: 42px;
}
.crancy-profile__hover .crancy-balance__img img {
  max-width: 18px;
}
.crancy-balance.crancy-profile__hover::before {
  left: initial;
  right: 24px;
}
.crancy-wallet__list-single {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e3e4fe;
  padding: 10px 20px;
  background: #fff;
  border-radius: 5px;
}
.crancy-preview__connect {
  background: #f8f8f8;
  border-radius: 1rem;
}
.crancy-followers__modal .modal-content.crancy-preview__connect {
  width: 580px !important;
}
.crancy-wallet__list {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.crancy-wallet__list-single .crancy-balance__img {
  background: #eeefff;
}

.view-wallet__btn {
  text-align: center;
  margin-top: 20px;
}
.view-wallet__btn a {
  color: #9aa2b1;
  font-size: 18px;
}
.view-wallet__btn.view-trnsc a:hover {
  color: #0a82fd;
}
.crancy-header__group .crancy-balance-name a {
  font-weight: 400;
}
/* crancy Notification Single */
.crancy-alarm__hover.all-notificaiton {
  position: relative;
  left: initial;
  right: inherit;
  opacity: 1;
  visibility: visible;
  transform: none;
  margin: 0;
  top: 0;
  width: 100%;
  box-shadow: none;
  z-index: 22;
  padding: 0;
  top: 0 !important;
}
.crancy-alarm__hover.all-notificaiton .crancy-balance_list {
  padding: 0;
}
.crancy-alarm__hover.all-notificaiton:before {
  display: none;
}
.crancy-alarm__hover.all-notificaiton .crancy-balance-name {
  font-size: 16px;
}

.dashboard-banner__content {
  padding-bottom: 14px;
}
.crancy-header__author.crancy-header__author-two {
  margin-bottom: 30px;
}
.crancy-header__author-title--two {
  color: #fff;
  margin-bottom: 1px;
  letter-spacing: 0.025em;
}
.dashboard-banner__group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.crancy-header__author-text--two a {
  color: #fff;
}
.crancy-profile__count {
  width: 20px;
  height: 20px;
  color: #fff;
  display: inline-block;
  border-radius: 100%;
  font-size: 12px;
  text-align: center;
  position: relative;
  top: -18px;
  right: -5px;
}

.crancy-balance.crancy-profile__hover .crancy-balance-name a {
  font-size: 18px;
}

.dashboard-banner__main .crancy-header__author-img img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.crancy-balance__img.crancy-profile__img-one {
  background: #effafe;
}
.crancy-balance__img.crancy-profile__img-two {
  background: #feecff;
}
.crancy-balance__img.crancy-profile__img-three {
  background: #eaf7f0;
}
.crancy-balance__img.crancy-profile__img-four {
  background: #eeefff;
}
.crancy-balance__img.crancy-profile__img-five {
  background: #fdefef;
}

/* Single Notification */
.crancy-inner__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.all-notificaiton__inner {
  display: flex;
  justify-content: right;
  gap: 20px;
  border: none;
}

.crancy-notifications__inner {
  padding: 25px;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
}
.crancy-inner__page-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.all-notificaiton__inner .list-group-item {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  color: #9aa2b1;
  font-weight: 500;
  font-size: 18px;
  position: relative;
}
.all-notificaiton__count {
  position: absolute;
  right: -15px;
  top: -14px;
  background: #0a82fd;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  border-radius: 100%;
  color: #fff;
}
.all-notificaiton__inner .list-group-item:hover,
.all-notificaiton__inner .list-group-item.active {
  color: #0a82fd;
}
.crancy__toggle {
  font-size: 12px;
  color: #9aa2b1;
  position: relative;
  top: 2px;
  transform: rotate(90deg);
}
.crancy__dropdown {
  padding-left: 38px;
}
.crancy__dropdown i {
  padding: 0;
  margin: 0;
  background: transparent !important;
  font-size: initial;
  color: #9aa2b1 !important;
}
.crancy-dashboard-menu .crancy__toggle:before {
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-family: var(--fa-style-family);
  font-weight: var(--fa-style, 900);
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}
.crancy-dashboard-menu .collapsed .crancy__toggle {
  transform: rotate(0);
}
.crancy-dashboard-menu .collapsed .crancy__toggle::before {
  content: "\f054";
  color: #2f3032;
}
.crancy-dashboard-menu .crancy__toggle:before {
  content: "\f054";
  color: #0a82fd;
}
.crancy-dashboard-menu li:hover .crancy__toggle:before,
.crancy-dashboard-menu li.active .crancy__toggle:before {
  color: #0a82fd;
}
.crancy-header__options {
  display: flex;
  align-items: center;
  gap: 30px;
}
.crancy-header__dropdown {
  display: flex;
  align-items: center;
}
.crancy-header__dropdown--single {
  border-right: 1px solid #e4f2ffb5;
  padding-right: 20px;
  margin-right: 20px;
}
.crancy-header__dropdown--single:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.crancy-language.crancy-currency .crancy-balance.crancy-language__list {
  right: initial;
  left: 0;
  width: 120px;
  margin-left: -33px;
}

.crancy-language.crancy-currency .crancy-balance.crancy-language__list {
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}

@media only screen and (min-width: 768px) and (max-width: 1550px) {
  .crancy-header__author,
  .crancy-header__zoom {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-header__form-inner {
    width: 270px;
    position: relative;
    max-width: 100%;
  }
  .crancy-element__form {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-header__form-inner {
    width: 175px;
    position: relative;
  }
  .crancy-header__group {
    gap: 15px;
  }
  .crancy-amount__icon {
    margin-right: 16px;
    font-size: 18px;
  }
  .crancy-header__right .crancy-amount__icon {
    margin-right: 0;
  }
  .crancy-header__inner {
    gap: 10px;
    height: 70px;
  }
  .crancy-amount__digit {
    font-size: 18px;
  }
  .crancy-balance.crancy-profile__hover {
    right: -15px;
    top: 83px !important;
  }
  .crancy-balance.crancy-profile__hover::before {
    left: initial;
    right: 15px;
  }
  .logout-button .primary {
    width: fit-content;
    padding: 8px 20px 8px 8px;
  }
  .crancy__dropdown {
    padding: 0;
  }
  .crancy-header__left .crancy-header__form {
    display: none;
  }
  .crancy-header__author-img {
    max-width: 52px;
  }
  .crancy-header__author-img img {
    width: 52px;
    height: 52px;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-header__form-inner {
    width: 175px;
    position: relative;
  }
  .crancy-header__group {
    gap: 15px;
    width: 100%;
  }

  .crancy-header__right {
    display: flex;
    gap: 30px;
    align-items: center;
    width: 100%;
  }
  .crancy-amount__icon {
    margin-right: 8px;
    font-size: 18px;
  }
  .crancy-header__amount {
    padding: 5px 15px;
    gap: 8px;
  }
  .crancy-header__inner {
    gap: 10px;
    height: initial;
  }
  .crancy-amount__digit {
    font-size: 18px;
  }
  .crancy-header__left .crancy-header__form {
    display: none;
  }
  .crancy-header__plus {
    display: none;
  }
  .crancy-header__right {
    gap: 5px;
  }
  .crancy-header__group {
    gap: 10px;
  }
  .crancy-header .crancy-header__author-content {
    display: none;
  }
  .crancy-smenu .logo {
    margin: 0;
    min-height: 80px;
  }
  .admin-menu__two.mg-top-50,
  .logout-button {
    margin-top: 20px;
  }
  .crancy-balance {
    margin: 0;
    z-index: 5555;
    width: 300px;
    top: 70px;
  }
  .crancy-balance.crancy-profile__hover::before {
    right: 15px;
  }
  .crancy__dropdown {
    padding: 0;
  }
  .logout-button .primary {
    width: fit-content;
    padding: 8px;
  }
  .crancy-balance.crancy-profile__hover {
    left: initial;
    right: -12px;
    top: 82px;
  }
  .crancy-balance::before {
    left: 40px;
  }
  .crancy-balance_list li {
    gap: 10px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .crancy-balance-name {
    font-size: 16px;
  }
  .crancy-balance-amount {
    font-size: 16px;
  }
  .crancy-balance-amount {
    min-width: 100px;
  }
  .crancy-balance__img img {
    max-width: 18px;
  }
  .crancy-header__author-img {
    max-width: 50px;
  }
  .crancy-header__zoom,
  .crancy-header__alarm.crancy-header__alarm--messages,
  .crancy-header__nav {
    display: none;
  }
  .crancy-header__dropdown {
    padding-right: 0;
  }
  .crancy-header__dropdown--single {
    padding-right: 10px;
    margin-right: 10px;
  }
  .crancy-header__dropdown--single.crancy-language.crancy-currency {
    display: none;
  }
  .crancy-header__dropdown--single {
    border: none;
    padding: 0;
    margin: 0;
  }
  .crancy-balance.crancy-language__list {
    padding: 15px 10px;
    top: 0;
  }
  .crancy-balance:before {
    content: "";
    border-bottom: 25px solid #fff;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }
  .crancy-notifications {
    padding: 30px;
  }
  .crancy-language a {
    gap: 5px;
  }
  .crancy-language span {
    font-size: 12px;
    line-height: 18px;
  }
  .crancy-header__right,
  .crancy-header__options {
    gap: 20px;
  }
}

/* Sidebar CSS */
.crancy-sidebar {
  position: sticky;
  top: 0;
}
.crancy-sidebar.sticky {
  top: 130px;
}
.crancy-sidebar__single {
  padding: 25px 22px;
  background: #fff;
  border-radius: 15px;
  margin-bottom: 40px;
}
.crancy-sidebar__single:last-child {
  margin: 0;
}
.crancy-sidebar__heading,
.fc-header-toolbar.fc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4f2ff;
  padding-bottom: 15px !important;
  margin-bottom: 15px !important;
}
.crancy-sidebar__heading.no-border {
  border-bottom: 0px;
  padding-bottom: 0 !important;
}
.crancy-sidebar__title {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}
.crancy-sidebar_btn {
  color: #9aa2b1;
  font-size: 14px;
  font-weight: 400;
}

.crancy-heading__amount-dropdown {
  color: #2f3032;
  font-size: 20px;
  padding: 0;
  margin: 0;
  border: none !important;
}
.crancy__amount-dropdown {
  gap: 10px;
  display: flex;
}
.crancy__amount-dropdown img {
  max-width: 42px;
}
.crancy-heading__amount-dropdown:after {
  float: right;
  margin-left: 10px;
  position: absolute;
  right: -20px;
  top: 11px;
  font-size: 25px;
}

.crancy-amount__statics {
  margin-top: 18px;
}
.crancy-amount__statics__title {
  font-size: 20px;
  margin-bottom: 5px;
}
.crancy-sidebar__single .crancy-amount__statics__title {
  margin: 0;
}
.crancy-amount__statics__text {
  color: #27ae60;
  font-size: 14px;
  margin: 0;
}
.nav-tab {
  border: none !important;
}

.crancy-sidebar__cstatics-title {
  font-size: 16px;
  text-align: center;
  color: #2f3032;
  margin-bottom: 15px;
  font-weight: 500;
  font-weight: 400;
}
.crancy-progress-list li {
  display: inline-block;
  color: #9aa2b1;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 3px;
}
.crancy-progress-list__color {
  width: 16px;
  height: 16px;
  background: #0a82fd;
  position: relative;
  display: inline-block;
  border-radius: 100%;
  margin-right: 5px;
  min-width: 16px;
  min-height: 16px;
}
.crancy-progress-list {
  flex-direction: column;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.crancy-progress-list li p span {
  color: #9aa2b1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crancy-progress-list li b {
  color: #2f3032;
  font-weight: 500;
}
.crancy-progress-list li p {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  justify-content: space-between;
  color: #2f3032;
  font-size: 16px;
}
.crancy-progress-list.crancy-progress-list__inline {
  flex-direction: row;
  padding: 0 !important;
  gap: 15px;
}
.crancy-progress-list.crancy-progress-list__space {
  justify-content: space-between;
}
.crancy-progress-list.crancy-progress-list__white {
  color: #fff;
}
.crancy-progress-list.crancy-progress-list__white li p {
  color: #fff;
}
.crancy-progress-list.crancy-gap-50 {
  gap: 50px;
}

/* Sidebar Dropdown Tabs */
.crancy-sidebar_dropdown {
  border: none;
  /* padding: 0; */
  background: #fff;
  color: #fff;
  border-radius: 10px !important;
  overflow: hidden;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 4px 87px 0px;
  inset: auto !important;
  right: 0 !important;
  top: 0 !important;
}
.crancy-sidebar_dropdown a {
  border: none;
  background: transparent;
}
.crancy-sidebar_dropdown a {
  color: #9aa2b1;
  font-size: 12px;
  padding: 2px 20px;
  border-radius: 14px !important;
  font-weight: 400;
  background: transparent !important;
}
.crancy-sidebar_dropdown a:hover {
  opacity: 0.8;
  color: #0a82fd;
}
.crancy-sidebar_dropdown a.active {
  background: transparent;
  color: #0a82fd;
}
.crancy-sidebar__cicon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 86px;
  margin-top: -43px;
  margin-left: -43px;
  z-index: 0;
}
.crancy-sidebar__cside-one {
  position: relative;
}
.crancy-progress-inline__clist li span {
  color: #2f3032;
}
/* Creator Lists */
.crancy-sidebar__creatorlist li {
  display: flex;
  width: 100%;
}
.crancy-sidebar__creator {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}
.crancy-sidebar__creator a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}
.crancy-sidebar__creator-badge {
  font-size: 16px;
  font-weight: 400;
}
.crancy-sidebar_btn.crancy-heading__tabs {
  padding: 0;
  border: none;
  color: #2f3032;
  font-size: 14px !important;
}
.crancy-sidebar_btn.crancy-heading__tabs:after {
  display: ;
}
.crancy-sidebar_btn.crancy-heading__tabs i {
  padding-left: 5px;
}
.crancy-sidebar__creator-name {
  display: flex;
  flex-direction: column;
  color: #2f3032;
  line-height: 18px;
  font-weight: 500;
  font-size: 16px;
  gap: 3px;
}
.crancy-sidebar__creatorlist {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.crancy-sidebar__creator-link {
  font-size: 16px;
  font-weight: 400;
  color: #9aa2b1;
}
.crancy-sidebar__button-btn {
  width: 26px;
  height: 26px;
  line-height: 26px;
  background: #e3e4fe;
  text-align: center;
  border-radius: 100%;
  font-size: 14px;
  color: #0a82fd;
  text-align: center;
}
.crancy-sidebar__button-btn svg {
  max-width: 16px;
}
.crancy-sidebar__button-btn.crancy-request_request {
  background: #0a82fd;
  color: #fff;
}
.crancy-sidebar__creator img {
  max-width: 32px;
  border-radius: 100%;
  min-width: 32px;
  min-height: 32px;
}
.crancy-sidebar__button {
  display: flex;
  align-items: center;
  gap: 5px;
}
.crancy-sidebar__list li a {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: #9aa2b1;
}
.crancy-sidebar__list li img {
  max-width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
}
.crancy-sidebar__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.crancy-multiple__adropdownn .crancy__amount-dropdown {
  font-size: 20px;
  align-items: center;
  font-weight: 700;
}
.crancy-multiple__adropdownn .list-group-item {
  margin-bottom: 10px;
}
.crancy-multiple__adropdownn .crancy__amount-dropdown:last-child {
  margin: 0;
}
.crancy-multiple__adropdownn .crancy__amount-dropdown img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.crancy-multiple__adropdownn .crancy-sidebar_dropdown {
  padding: 8px 10px;
  background: #fff !important;
}
.crancy-multiple__adropdownn .crancy-sidebar_dropdown a {
  color: #2f3032;
  padding: 0;
}

.crancy-sidebar__widget {
  margin-bottom: 30px;
}
.crancy-sidebar__widget:last-child {
  margin: 0;
}
.crancy-sidebar__creatorlist--sellers .crancy-sidebar__creator b {
  font-weight: 400;
}
.crancy-progress-list--group {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 0px) and (max-width: 1550px) {
  .crancy-progress-list--group {
    gap: 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1550px) {
  .crancy-sidebar__widget {
    width: 50%;
  }
  .crancy-sidebar__single {
    min-height: 478px;
  }
}

/*======================================
   End Header & Sidebar CSS
========================================*/

/*======================================
   Widgets CSS
========================================*/
/* Progress Bar */
.crancy-progressbar__single {
  position: relative;
  text-align: center;
  background: #d9d9d9;
  width: 100%;
  border-radius: 100px;
  height: 8px;
}
.crancy-progressbar__single .crancy-progressbar__bar {
  border-radius: 100px;
}
.crancy-progressbar__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}
.crancy-progressbar__stats {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy-progressbar__count {
  color: #2f3032;
  font-size: 14px;
}
.crancy-progressbar__percent {
  display: flex;
  align-items: center;
  gap: 5px;
}
.crancy-progressbar__title {
  margin: 0;
  color: #9aa2b1;
  font-size: 14px;
  font-weight: 400;
}

/* Chart JS */
.charts-main {
  background: #fff;
  padding: 25px 30px;
  border-radius: 15px;
  box-shadow: 0px 9px 95px rgba(0, 0, 0, 0.05);
}
.crancy-chart__inside {
  position: relative;
}
.charts-supporttracker {
  position: relative;
}
.crancy-chart__inside--parcent {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  left: 50%;
  margin-left: -28px;
  margin-top: -13px;
}
.charts-main__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.charts-main__heading.charts-main__heading--v2 {
  align-items: baseline;
}
.charts-supporttracker--tittle {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0;
}
.charts-main__middle {
  display: flex;
  gap: 15px;
  align-items: center;
}
.charts-main__title {
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  color: #2f3032;
}
.crancy-current {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #0a82fd;
}
.charts-main__middle-text {
  margin: 0;
}
.charts-main__middle-text::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  background: #f2c94c;
  border-radius: 100%;
  margin-top: -8px;
}
.charts-main__middle-single {
  position: relative;
}
.charts-main__middle-text {
  padding-left: 25px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.charts-main__middle-text.crancy-total__sales::before {
  background: #f2c94c;
}
.charts-main__middle-text.crancy-social__sales::before {
  background: #27ae60;
}
.charts-main__middle-text.crancy-last__sales::before {
  background: #f2c94c;
}
.charts-main__middle-text.crancy-social__search::before {
  background: #ef5da8;
}

.charts-main__middle-single span {
  color: #2f3032;
  padding-left: 10px;
}
.charts-main__middle.charts-main__middle--v2 {
  gap: 50px;
}
.charts-main__middle.charts-main__middle--v2 .charts-main__middle-text:before {
  background: #f2c94c;
}
.charts-main__middle.charts-main__middle--v2
  .charts-main__middle-text.crancy-total__sales:before {
  background: #0a82fd;
}

.charts-main__invest .charts-main__one {
  padding-top: 24px;
}

.charts-home-one .charts-main__title {
}
.charts-home-one .charts-main__middle-text {
}
.charts-main__showcase {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}
.crancy-history__content {
  margin: 0;
  align-items: center;
}
.crancy-wc__form-inner-showcase {
  background: #fff;
  padding: 40px;
  border-radius: 15px;
}
.charts-main {
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 9px 95px rgba(0, 0, 0, 0.05);
}
.crancy-sixth-one {
  width: 65%;
}
.crancy-sixth-two {
  width: 35%;
}
.charts-home-one {
  min-height: 352px;
}

.crancy-chart__one {
  min-width: 100% !important;
  height: 275px !important;
  min-height: 275px !important;
}

.crancy-chart__two {
  min-width: 100% !important;
  min-height: 250px !important;
  height: 268px !important;
}
.crancy-chart__three {
  min-width: 100% !important;
  min-height: 400px !important;
  height: 415px !important;
}
.crancy-chart__inside.crancy-chart__five {
  width: 100%;
  height: 240px;
}
.crancy-sidebar__cside-one {
  height: 260px;
  width: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-chart__audience {
  min-width: 100% !important;
  min-height: 350px;
  height: 350px;
}
.crancy-chart__traffic {
  min-width: 280px !important;
  min-height: 280px;
  width: 280px;
  height: 280px;
}
.crancy-chart__client-stats {
  min-width: 100% !important;
  min-height: 70px !important;
  height: 75px !important;
}
.crancy-chart__activity {
  height: 300px;
}
.crancy-chart__traffic .myChart-progress__number {
  font-size: 18px;
}

.charts-main__statics {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.charts-main__statics--title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.charts-main__statics--text {
  font-size: 14px;
}

.charts-main__middle.charts-main__middle__reduce {
  gap: 30px;
}
.crancy-chart_ptask {
  min-height: 200px;
  max-width: 270px;
  max-height: 270px;
  width: 100%;
}

.crancy-progress__side {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-progress__side canvas {
  /* width: 190px; */
  /* height: 190px; */
}
.crancy-progress__side.circle_side_two {
  transform: scale(0.84);
}
.crancy-progress__side.circle_side_three {
  transform: scale(0.67);
}

@media only screen and (min-width: 1100px) and (max-width: 1550px) {
}

@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-chart__three {
    min-height: 340px !important;
    height: 340px !important;
  }
}

@media only screen and (max-width: 767px) {
  .charts-main__heading {
    flex-direction: column;
    align-items: baseline;
  }
  .charts-main__heading .crancy-chart__dropdown {
    margin-top: 12px;
  }
  .crancy-chart__one,
  .crancy-chart__three,
  .crancy-chart__audience {
    height: 250px !important;
    min-height: 250px !important;
  }
  .charts-main__heading.charts-main__heading--v2 {
    align-items: baseline;
    flex-direction: row;
  }
  .crancy-progress-list.crancy-gap-50 {
    gap: 20px;
  }
}
/* Welcome CTA */
.welcome-cta {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  position: relative;
  border-bottom: 2px solid #0a82fd;
  box-shadow: 0px 0px 5px #0000000d;
  padding: 2.25rem;
}
.welcome-cta__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  justify-content: right;
}
.welcome-cta__title {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 500;
}
.welcome-cta__text {
  color: #9aa2b1;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.025em;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .welcome-cta__button {
    width: 32%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1278px) {
}
@media only screen and (max-width: 1278px) {
  .welcome-cta {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
  }
  .welcome-cta__button {
    justify-content: initial;
  }
}

/* Fun Fact */
.crancy-funfact {
  padding: 40px 30px 70px;
  background: #0a82fd;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  flex-wrap: wrap;
}
.crancy-funfact__single {
  display: flex;
  gap: 25px;
  align-items: center;
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  flex-direction: column;
  width: 25%;
}
.crancy-funfact__single:last-child {
  border: none;
  padding-right: 0;
  margin-right: 0;
}
.crancy-funfact__icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #3b9bfd;
  border-radius: 100%;
  text-align: center;
}
.crancy-funfact__icon svg {
  fill: #fff;
  max-width: 42px;
}
.crancy-funfact__img--v2 {
  max-width: 42px !important;
}
.crancy-funfact__img.crancy-funfact__img--v4 {
  max-width: 34px;
}
.crancy-funfact__content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}
.crancy-funfact__title {
  color: #fff;
  font-size: 42px;
  margin: 0;
  font-weight: 400;
  line-height: initial;
}
.crancy-funfact__text {
  margin: 0;
  font-size: 16px;
  color: #fff;
  line-height: initial;
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-funfact__single {
    padding-right: 3.5%;
    margin-right: 3.5%;
    width: 20%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .crancy-funfact__title {
    font-size: 32px;
    line-height: initial;
  }
  .crancy-funfact__icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-funfact {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .crancy-funfact__single {
    padding-right: 0;
    margin-right: 0;
    width: 48%;
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .crancy-funfact {
    display: block;
    padding: 20px 20px 50px;
    text-align: center;
  }
  .crancy-funfact .crancy-funfact__single {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    text-align: center;
    border: none;
  }
  .crancy-funfact__content {
    text-align: center;
    margin-top: 15px;
  }
  .crancy-funfact__content {
    text-align: left;
    align-items: center;
    margin-top: 15px;
  }
  .crancy-funfact__icon {
    display: inline-block;
  }
  .crancy-funfact__icon svg {
    max-width: 32px;
  }
}

/* Calendar CSS */
.pika-button:hover,
.pika-row.pick-whole-week:hover .pika-button {
  background-color: #0a82fd;
}
#calendar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-col-header,
.fc-daygrid-body,
.fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc-header-toolbar.fc-toolbar {
  margin-bottom: 15px !important;
}
.fc-header-toolbar {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
.fc .fc-daygrid-day-number {
  padding: 4px;
  position: relative;
  z-index: 4;
  min-width: 30px;
  min-height: 30px;
}
.crancy-default-cd .fc-toolbar-title {
  font-size: 24px !important;
  font-weight: 500 !important;
}
.crancy-default-cd .fc-header-toolbar {
  padding: 0;
}
.fc-col-header {
  margin: 0;
}
.crancy-default-cd .fc-daygrid-day-number {
  color: #2f3032;
  font-weight: 400;
  padding: 0;
}
.crancy-default-cd td,
.crancy-default-cd th {
  border: none;
}
.crancy-default-cd .fc-scrollgrid {
  border: none;
}
.crancy-default-cd .fc-col-header-cell-cushion {
  font-size: 14px;
  color: #0a82fd;
  font-weight: 500;
}
.crancy-default-cd .fc-daygrid-day.fc-day-today {
  background: transparent;
  border-radius: 100%;
  min-width: 46px;
  min-height: 38px;
  line-height: 38px;
  position: relative;
}
.crancy-default-cd .fc-daygrid-day.fc-day-today a {
  background: #0a82fd;
  color: #fff !important;
  border-radius: 100%;
  min-width: 28px;
  min-height: 28px;
  line-height: 28px;
  padding: 0;
  margin: 0;
  max-height: 32px;
}
.crancy-default-cd .fc-daygrid-day.fc-day-today a {
  color: #fff;
}
.crancy-default-cd .fc-daygrid-day-number {
  color: #2f3032;
  font-weight: 400;
  font-size: 14px !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  font-size: 14px !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}
.fc .fc-daygrid-day-frame {
  /* min-height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc-view-harness {
  height: 340px !important;
}
.fc-today-button {
  display: none !important;
}
.crancy-default-cd .fc-button-primary {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  /* width: initial; */
  height: initial;
  width: 15px !important;
  box-shadow: none !important;
}
.crancy-default-cd .fc-button span {
  color: #c3c3c3;
}

.crancy-default-cd .fc-button.fc-next-button span {
  color: #54a7fe;
}
.crancy-default-cd .fc-scrollgrid {
  margin: 0;
}
.crancy-default-cd .fc-scrollgrid-sync-table {
  margin: 0;
  margin-left: -3px;
}
.crancy-default-cd .fc-scrollgrid-sync-table td {
  width: 32px;
  height: 38px;
  line-height: 32px;
  text-align: center;
  padding: 0;
  margin: 0;
  border: none;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: none !important;
}
.fc-scrollgrid {
  border: none !important;
}
.crancy-default-cd__event {
  background: #fff;
  border: 1px solid #e4f2ff;
  border-radius: 15px;
  overflow: hidden;
}
.crancy-default-cd__event .fc-col-header-cell-cushion {
  display: inline-block;
  font-size: 18px !important;
}
.crancy-default-cd__event .fc-header-toolbar {
  background: #ffffff;
  padding: 20px 30px;
}
.crancy-default-cd__event .fc-header-toolbar .fc-toolbar-title {
  color: #0a82fd;
}
.crancy-default-cd__event .fc-button-group button span {
  background: #c5e2ff !important;
  border-radius: 4px;
  opacity: 1 !important;
  color: #d8dbe1 !important;
  border: 1px solid #d8dbe1;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
}
.crancy-default-cd__event .fc-button-group button span:hover {
  background: #0a82fd !important;
  border-color: transparent !important;
  color: #fff !important;
}
.crancy-default-cd__event .fc-button-group {
  gap: 22px;
}
.crancy-default-cd__event .fc-button-group button:hover span {
  background: #0a82fd !important;
  color: #fff !important;
}
.crancy-default-cd__event .fc-daygrid-day-number {
  width: auto;
  height: auto;
  min-width: auto;
  min-height: auto;
}

.crancy-default-cd__event .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  min-width: 60px;
  min-height: 60px;
  line-height: 60px;
  margin-top: -10px;
}
.crancy-default-cd__event .fc-view-harness {
  height: 700px !important;
}
.crancy-default-cd.crancy-default-cd__event .fc-daygrid-day-number {
  font-size: 18px !important;
}
.event-color {
  position: absolute !important;
  left: -42px;
  bottom: -80px;
  z-index: 3333333333333 !important;
  padding: 3px 10px !important;
  font-size: 14px !important;
  font-weight: 400;
  border: none !important;
  background-color: #0a82fd !important;
  border-radius: 4px !important;
}
.event-color::before {
  content: "";
  position: absolute !important;
  border-bottom: 13px solid #0a82fd;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  top: -12px;
  left: 10px;
  left: 28px;
  margin-left: -13px;
}
.event-color.event-color__1 {
  background-color: #219653 !important;
}
.event-color.event-color__1.event-color::before {
  border-bottom-color: #219653 !important;
}
.event-color.event-color__3 {
  background-color: #f2994a !important;
}
.event-color.event-color__3.event-color::before {
  border-bottom-color: #f2994a !important;
}

.event-color.event-color__4 {
  background-color: #ef5da8 !important;
}
.event-color.event-color__4.event-color::before {
  border-bottom-color: #ef5da8;
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-default-cd__event .fc-daygrid-day.fc-day-today::before {
    width: 80px;
    height: 80px;
    top: -22px;
    left: 6px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .crancy-default-cd__event .fc-daygrid-day.fc-day-today::before {
    width: 80px;
    height: 80px;
    top: -22px;
    left: 6px;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-default-cd__event .fc-daygrid-day.fc-day-today::before {
    width: 35px;
    height: 35px;
    top: 0%;
    left: 2px;
  }
  .crancy-default-cd.crancy-default-cd__event .fc-daygrid-day-number {
    font-size: 14px !important;
  }
  .event-color {
    padding: 0px 5px !important;
    font-size: 10px !important;
  }
  .crancy-default-cd__event
    .fc-daygrid-day.fc-day-today
    .fc-daygrid-day-number {
    min-width: 30px;
    min-height: 30px;
    line-height: 30px;
    margin-top: 0;
  }
}
.crancy-default-cd__event .fc-daygrid-day.fc-day-today .event-color {
  left: -58px;
  bottom: -100px;
}
.crancy-default-cd__event .fc-scrollgrid-sync-table {
  padding-top: 20px !important;
  margin-top: 20px !important;
}

.crancy-default-cd__event .fc-daygrid-body.fc-daygrid-body-unbalanced {
  padding: 0 30px;
  padding-top: 30px;
}
.crancy-default-cd__event .fc-scroller-harness {
  padding: 0 30px;
}
/* Map CSS */
#crancy-map svg,
#crancy-map .jvectormap-container {
  min-height: 270px;
  max-height: 271px;
}
.jvectormap-tip {
  background-color: #0a82fd;
  border: none;
  color: #fff;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: none;
}
#crancy-map {
  position: relative;
}
.jvectormap-zoomout {
  top: 37px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  #crancy-map svg,
  #crancy-map .jvectormap-container {
    min-height: 225px;
    max-height: 225px;
  }
  .crancy-table__main .crancy-table__body tr td {
    padding: 5px 6px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 991px) {
  .crancy-default-cd__event .fc-daygrid-day.fc-day-today .event-color {
    left: -58px;
    bottom: -102px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .crancy-default-cd__event .fc-daygrid-day.fc-day-today .event-color {
    left: -45px;
    bottom: -90px;
  }
  .crancy-default-cd__event .fc-scroller-harness {
    padding: 0 20px;
  }
  .crancy-default-cd__event .fc-daygrid-body.fc-daygrid-body-unbalanced {
    padding: 0 20px !important;
  }
}
.fc-scroller,
.fc-scroller.fc-scroller-liquid-absolute {
  overflow-y: scroll !important; /* Enable scrolling */
  scrollbar-width: none !important; /* Hide the scrollbar on Firefox */
  -ms-overflow-style: none !important; /* Hide the scrollbar on Edge and IE */
}

.fc-scroller::-webkit-scrollbar,
.fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar {
  width: 0 !important; /* Hide the scrollbar on Chrome and Safari */
  height: 0 !important;
  scrollbar-width: none !important; /* Hide the scrollbar on Firefox */
}

.scroll-container {
  overflow: hidden; /* Hide the main scrollbar */
  position: relative; /* Create a containing block for absolute positioning */
}

.fc-scroller.fc-scroller-liquid-absolute {
  overflow: auto; /* Enable scrolling within the container */
  height: 100%; /* Make sure the container takes the full height */
  margin-right: -20px; /* Compensate for potential scrollbar width to avoid layout shifts */
  padding-right: 20px; /* Create space for the hidden scrollbar */
}

.fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the thumb (drag handle) */
}

.fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar-track {
  background-color: transparent; /* Hide the track (scroll track) */
}

/* Banner & Cards CSS */
.crancy-hbanner {
  background: #0a82fd;
  border-radius: 12px;
  padding: 30px 35px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 450px;
  padding-top: 60px;
}
.crancy-hbanner .crancy-btn.crancy-white:hover {
  background: #f2c94c;
}
.crancy-hbanner .crancy-btn.crancy-ybcolor:hover {
  background: #fff;
}
.crancy-hbanner__title {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
}
.crancy-hbanner__text {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}
.crancy-hbanner__button {
  margin-top: 40px;
}
.crancy-hbanner__button {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 25px;
}
.crancy-hbanner__img {
  min-width: 345px;
}

.crancy-hbanner__content {
  max-width: 65%;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-hbanner__img {
    min-width: 300px;
  }
  .crancy-hbanner {
    padding: 52px 25px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .crancy-hbanner__img {
    display: none;
  }
  .crancy-hbanner__title {
    font-size: 24px;
  }
  .crancy-hbanner__button {
    margin-top: 30px;
  }
  .crancy-hbanner {
    min-height: initial;
    padding: 35px;
    background-size: 60%;
    background-position: right bottom;
  }
  .crancy-hbanner__content {
    width: 100%;
    max-width: 100%;
  }
}
.crancy-preview__modal--close {
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 2px solid rgba(55, 69, 87, 0.6);
  border-radius: 100%;
  font-size: 20px;
  margin: 0 !important;
  padding: 0 !important;
}
.modal-header.crancy__modal__header {
  border-bottom: 1px solid rgba(83, 86, 251, 0.16);
  padding: 25px 40px;
}
.crancy-preview__modal-content {
  border-radius: 15px;
}
.crancy-preview__modal-title {
  font-size: 26px;
}
.crancy__item-button--single.crancy__item-button--cancel {
  font-weight: 400;
}
button.crancy-item__btn {
  min-width: 126px;
}
.crancy-modal__body {
  padding: 30px;
}
.crancy-modal__body .trending-action__single {
  max-width: 270px;
  margin: 0 auto;
}
.modal-content .btn-close {
  font-size: 15px !important;
  background: none !important;
  border: none;
  opacity: 1;
}
.crancy-preview__close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.crancy-preview__close-img {
  max-width: 135px;
  margin-bottom: 28px;
}
.crancy-preview__close-title {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.crancy-close__body {
  padding: 40px 60px;
}
.crancy-preview__modal-content {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 165px rgba(87, 87, 87, 0.25);
  border-radius: 12px;
  padding: 0;
  margin: 0 !important;
  border: none;
  margin: 0 auto;
}
.crancy-close__modal-close {
  min-width: 600px;
}

.crancy-close__modal-close .modal-content {
  margin: 0 auto;
}

/* Crancy Modal */
.email_modal--inner {
  margin-right: 70px;
  margin-bottom: 70px;
  align-items: end;
}
.email_modal--inner .crancy-main-form__group textarea {
  height: 250px;
}
.crancy-project__modal .crancy-main-form__button {
  justify-content: right;
}
.crancy-default__modal .modal-dialog {
  min-width: 600px;
  width: 600px;
}

.crancy-default__modal.crancy-task__modal .modal-dialog {
  width: 800px !important;
  min-width: 800px;
}
@media only screen and (min-width: 768px) and (max-width: 1550px) {
  .crancy-default__modal .modal-dialog {
    min-width: 450px;
    width: 450px;
  }
  .crancy-default__modal.crancy-task__modal .modal-dialog {
    width: 650px !important;
    min-width: 650px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .crancy-preview__modal-content .crancy-main-form__group textarea {
    height: 160px;
  }
  .crancy-default__modal .modal-dialog {
    width: 100%;
    min-width: 100%;
    margin: 0;
    align-items: center;
  }
  .crancy-default__modal.crancy-task__modal .modal-dialog {
    width: 100% !important;
    min-width: 100%;
  }
  .crancy-main-form__group--rmargin {
    margin-bottom: 20px;
  }
}

/* crancy Table */
.crancy-table {
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.04);
}
.crancy-table__badge {
  min-width: 28px;
  min-height: 28px;
  display: inline-block;
  background: #0a82fd;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
}
.crancy-table__title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 500;
  color: #2f3032;
}
.crancy-table__head tr th,
.crancy-table__body tr td {
  width: 14%;
  vertical-align: middle;
  text-align: center;
}
.crancy-table__main .crancy-table__body tr td {
  padding: 14px 20px !important;
}
.crancy-table__head {
  background: #fafafa;
}
.crancy-table__body tr:last-child {
  border-bottom: 0px;
}
.crancy-table__body tr:hover {
  background: #f9fafb;
}
.crancy-table__product {
  display: flex;
  gap: 15px;
  align-items: center;
  text-align: left;
}
.crancy-table__product-img {
  max-width: 60px;
}
.crancy-table__product-img img {
  border-radius: 50%;
  min-width: 60px;
  min-height: 60px;
}
.crancy-table__product-title {
  margin: 0;
  font-size: 16px;
}
.crancy-table__product-desc {
  margin: 0;
  font-size: 14px;
  margin-top: 5px;
  line-height: initial;
}
.crancy-table__product-desc a {
  font-weight: 400;
  font-size: 16px;
}
.crancy-table__time {
  margin: 0;
}
.crancy-table__main .crancy-table__head tr th,
.crancy-table__main .crancy-table__body tr td {
  width: 14.28%;
  vertical-align: middle;
  text-align: left;
  padding: 11px 20px;
}
.crancy-table__main .crancy-table__column-1 {
  width: 12% !important;
  text-align: left !important;
}
.crancy-table__inner--title {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}
.crany-table__product--number {
  margin: 0;
}
.crany-table__product--number a {
  font-weight: 400;
}
.crancy-table__amount {
  display: flex;
  align-items: center;
  gap: 5px;
}
.crancy-table__amount img {
  max-width: 18px;
  height: ;
}
.crancy-table__main .crancy-table__column-2 {
  width: 26% !important;
}
.crancy-table__text {
  margin: 0;
  font-size: 16px;
  color: #2f3032;
}
.crancy-table__bid-text {
  color: #2f3032;
}
.crancy-table__status {
  font-size: 14px;
  color: #27ae60;
  text-align: center;
  padding: 6px 24px;
  border-radius: 4px;
  background: #e1f4e9;
  display: inline-flex !important;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}
.crancy-table__status svg {
  fill: #27ae60;
}
.crancy-table__status--cancel svg {
  fill: #eb5757;
}
.crancy-table__status.crancy-table__status--pending {
  background: #dfc4f8;
  color: #9b51e0;
}
.crancy-table__status.crancy-table__status--paid {
  background: #e8edff;
  color: #0a82fd;
}
.crancy-table__status.crancy-table__status--unpaid {
  background: #faefee;
  color: #ff4747;
}
.crancy-table__status.crancy-table__status--delete {
  background: #fdf9e9;
  color: #facc15;
}
.crancy-table__status.crancy-table__status--cancel {
  background: #f9cccc;
  color: #eb5757;
}
.crancy-table__status.crancy-table__status--down {
  background-color: #fce3f0;
  color: #ef5da8;
}
.crancy-table__head tr th {
  color: #9aa2b1;
  font-weight: 500;
  font-size: 16px;
  padding: 7px 20px;
}
.crancy-table__main table tbody a {
  font-weight: 400;
}
.crancy-table__main {
  margin: 0;
}
.crancy-table__main-sell .crancy-table__column-3 {
  width: 28%;
}
.crancy-table__main-sell .crancy-table__column-1,
.crancy-table__main-sell tr th,
.crancy-table__main-sell tr td {
  text-align: left;
}
.crancy-table__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  gap: 25px;
}
.crancy-dropdown__list .nav-link:after {
  display: none;
}
.crancy-dropdown__list {
  border: none !important;
}
.crancy-dropdown__list .nav-link {
  background: transparent !important;
  opacity: 1;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}
.dropdown-toggle svg {
  fill: #2f3032;
}
.crancy-table__heading .crancy-dropdown__list .nav-link {
  font-size: 14px;
  line-height: 24px;
}
.crancy-chart__dropdown.crancy-chart__dropdown__white .crancy-sidebar_btn,
.crancy-chart__dropdown.crancy-chart__dropdown__white svg {
  color: #fff !important;
  fill: #fff !important;
}
/* .crancy-tasksingle__group.crancy-tasksingle__group--activity img {
	width: 40px !important;
	height: 40px !important;
	border: 1.4px solid #FFFFFF;
	border-radius: 80px;
} */
.crancy-table__main.crancy-table__main-v2 .crancy-table__column-2,
.crancy-table__main.crancy-table__main-v2 .crancy-table__head tr th,
.crancy-table__main.crancy-table__main-v2 .crancy-table__body tr td {
  width: 14.28% !important;
}
.crancy-table__main.crancy-table__main-v2
  .crancy-table__head
  tr.crancy-table__column-1,
.crancy-table__main.crancy-table__main-v2
  .crancy-table__body
  td.crancy-table__column-1 {
  width: 30% !important;
}
.crancy-table__main.crancy-table__main-v2 .crancy-table__column-6 {
  padding-left: 0 !important;
}

.crancy-table__main.crancy-table__main-v2 .crancy-table__column-6 {
  min-width: 50% !important;
  padding: 0 !important;
}
.crancy-tasksingle__group.crancy-featured-user__lists span {
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  border-radius: 100%;
  padding: 0;
  width: 100%;
  height: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-table__right button {
  background: transparent;
}
.crancy-table__right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.crancy-table__right .crancy-header__form-inner {
  width: 290px;
}
#crancy-table__main_filter {
  display: none;
}
.crancy-table__ticket .crancy-table__column-2 {
  width: 34% !important;
}
.crancy-table__ticket .crancy-table__body tr td {
  padding: 10px 20px !important;
}
.crancy-table__ticket .crancy-table__inner--title a {
  font-weight: 400;
}
.crancy-table-file {
  display: flex;
  align-items: center;
  gap: 12px;
}
.crancy-table-file__title {
  margin: 0;
  font-weight: 400;
  color: #2f3032;
  font-size: 16px;
}
.crancy-table-file__icon {
  min-width: 34px;
  min-height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 4px;
}
.crancy-table_buttons {
  gap: 25px;
}
.crancy-table__body td {
  border-bottom: 1px solid #e4f2ff;
}

/* Table Pagination*/
.dataTables_paginate,
.crancy-pagination {
  text-align: center;
  display: flex;
  align-items: center;
}
.crancy-pagination {
  justify-content: end;
}
.dataTables_paginate .pagination a,
.crancy-pagination li a {
  width: 40px;
  height: 40px;
  border-radius: 72% !important;
  display: flex;
  align-items: center;
  text-align: center;
  background: transparent !important;
  border: none !important;
  color: #9aa2b1 !important;
  display: inline-block !important;
  padding: 0;
  line-height: 40px;
  font-weight: 400;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.dataTables_paginate .pagination,
.crancy-pagination ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.dataTables_paginate .pagination a:hover,
.crancy-pagination ul li a:hover,
.dataTables_paginate .paginate_button.active a,
.crancy-pagination ul li.active a {
  background: #eff7ff !important;
  color: #0a82fd !important;
}

.dataTables_paginate .paginate_button.page-item.previous a,
.dataTables_paginate .paginate_button.page-item.next a,
.crancy-pagination ul .crancy-pagination__button a {
  color: #9aa2b1 !important;
}
.dataTables_paginate .paginate_button.page-item.previous a:hover,
.dataTables_paginate .paginate_button.page-item.next a:hover,
.crancy-pagination ul .crancy-pagination__button:hover a {
  color: #0a82fd !important;
}
.dataTables_length label {
  color: #9aa2b1;
  font-weight: 400;
  font-size: 16px;
  margin: 0 !important;
}
.dataTables_length select {
  width: 68px !important;
  height: 42px;
  border: 1px solid #eeeff2 !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  padding: 0;
  text-align: center;
  min-height: 42px !important;
  text-align: left;
  padding: 0 12px;
  font-size: 16px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M4 6L8 10L12 6' stroke='%23A0AEC0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-size: 28% !important;
  background-position: calc(100% - 10px) center !important;
}
.crancy-table__tabs {
  padding: 0;
  box-shadow: none;
  overflow-x: scroll;
  position: relative;
}
.crancy-table__tabs::-webkit-scrollbar {
  display: none !important;
}
.crancy-table__tabs::-moz-scrollbar {
  display: none !important;
}
.crancy-table-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.crancy-pagination ul li.active a,
.crancy-pagination ul li:hover a {
  background-color: #eff7ff !important;
  color: #0a82fd !important;
}
.crancy-pagination ul .crancy-pagination__button:hover a {
  background: #e1e6fa !important;
  color: #304ffd !important;
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-table__support {
    width: 100%;
    overflow: scroll;
  }
  .crancy-table__support .crancy-table__body tr td {
    padding: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-table__product {
    flex-direction: column;
    align-items: first baseline;
    gap: 6px;
  }
  .crancy-table__product-title {
    font-size: 16px;
  }
  .crancy-table__body tr td {
    padding: 10px 0;
  }
  .crancy-table__amount {
    flex-direction: column;
  }
  .crancy-table__product-img {
    max-width: 46px;
  }
  .crancy-table__product-desc {
    line-height: 22px;
    margin: 0;
  }
  .crancy-table__status {
    font-size: 12px;
    padding: 2px 8px;
  }
  .crancy-table__text {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
  .crancy-table #myTabContent {
    overflow-x: auto;
  }
  .crancy-table__product-img img {
    min-width: 46px;
    min-height: 46px;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-table__product {
    flex-direction: column;
    align-items: first baseline;
  }
  .crancy-table__product {
    flex-direction: column;
    align-items: first baseline;
    gap: 6px;
  }
  .crancy-table__product-title {
    font-size: 16px;
  }
  .crancy-table__body tr td {
    padding: 10px 0;
  }
  .crancy-table__amount {
    flex-direction: column;
  }
  .crancy-table__product-img {
    max-width: 46px;
  }
  .crancy-table__product-desc {
    line-height: 22px;
    margin: 0;
  }
  .crancy-table__status {
    font-size: 12px;
    padding: 2px 8px;
  }
  .crancy-table__text {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
  .crancy-table {
    width: 100%;
    overflow-x: scroll;
  }
  .crancy-table__main,
  #crancy-table__main {
    width: 690px;
  }
  .crancy-table #myTabContent {
    overflow-x: auto;
  }
  .crancy-table__product-img img {
    min-width: 46px;
    min-height: 46px;
  }
  .crancy-table-bottom {
    flex-direction: column;
  }
}
@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .dataTables_paginate,
  .crancy-pagination {
    padding-bottom: 10px;
  }
}
/*======================================
   End Widgets CSS
========================================*/

/*======================================
	Chat & Email CSS
========================================*/
/* Chat Box */
.crancy-chatbox__one {
  width: 31%;
}
.crancy-chatbox__two {
  width: 69%;
}
.crancy-chatbox__sidebar {
  padding-top: 30px;
}
.crancy-chatbox__first-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin-bottom: 20px;
}
.crancy-chatbox__main {
  background: #fff;
  padding: 30px 25px;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
}
.crancy-chatbox {
  background: #fff;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
  padding-bottom: 0;
}
.crancy-chatbox__incoming-list li {
  width: 68%;
}
.crancy-chatbox__outgoing .crancy-chatbox__incoming-list {
  align-items: end;
}
.crancy-chatbox__title {
  font-size: 22px;
  margin: 0;
}
.crancy-chatbox__label.crancy-btn {
  padding: 3px 12px;
}
.crancy-chatbox__search {
  width: 100%;
}
.crancy-chatbox__search .crancy-header__form-inner {
  width: 100%;
}
.crancy-chatbox__search input {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.crancy-header__form .search-btn {
  color: #0a82fd;
  position: relative;
  background: transparent;
}
.crancy-header__form .crancy-header__form-inner {
  display: flex;
  align-items: center;
  background: #f5faff;
  border-radius: 8px;
  padding: 0 15px;
}
.crancy-header__form .crancy-header__form-inner input {
  background: transparent;
  padding: 0;
  font-size: 16px;
}
.crancy-header__form .crancy-header__form-inner .search-btn {
  height: 48px;
}
.crancy-header__command {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #a9a9aa;
  font-weight: 400;
}
.crancy-chatbox__search {
  margin-bottom: 30px;
}
.crancy-chatbox__search .search-btn {
  margin-top: -6px;
}
.crancy-chatbox__author {
  display: flex;
  gap: 15px;
  align-items: center;
}
.crancy-chatbox__author-title {
  margin: 0;
  font-size: 18px;
}
.crancy-chatbox__author-img img {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
}
.crancy-chatbox__author-online {
  width: 10px;
  height: 10px;
  background: #27ae60;
  position: absolute;
  border-radius: 100%;
  right: 4px;
  bottom: 4px;
}
.crancy-chatbox__author-img {
  position: relative;
}
.crancy-chatbox__author-desc {
  margin: 0;
  font-size: 14px;
  margin-top: 0px;
}
.crancy-chatbox__list li {
  display: flex;
  padding-left: 25px;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 25px;
  transition: all 0.3s ease;
}
.crancy-chatbox__list li:hover,
.crancy-chatbox__list li.active {
  background: #f0f7ff;
}
.crancy-chatbox__list li:hover .crancy-chatbox__inner,
.crancy-chatbox__list li.active .crancy-chatbox__inner {
  border-color: transparent;
}
.crancy-chatbox__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e4f2ff;
  width: 100%;
  transition: all 0.3s ease;
}
.crancy-chatbox__list li:last-child .crancy-chatbox__inner {
  border-bottom: none;
}
.crancy-chatbox__time {
  margin: 0;
  color: #9aa2b1;
  font-size: 14px;
}
.crancy-chatbox__unread {
  background: #eb5757;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 10px;
  text-align: center;
  color: #fff;
}
.crancy-chatbox__right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
}
.crancy-chatbox__toggle {
  cursor: pointer;
  min-width: 5px;
}
.crancy-chatbox__toggle svg {
  fill: #9aa2b1;
}
.crancy-chatbox__info {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.crancy-header__form-inner.crancy-header__form--inbox {
  width: 100%;
}
.crancy-header__form--inbox input {
  font-size: 16px;
}

.crancy-chatbox__explore {
  margin-right: 0;
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid #e4f2ff;
  height: 100%;
}
.crancy-chatbox__explore-head {
  background: #f0f7ff;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crancy-chatbox__author-img--head img {
  width: 60px;
  min-width: 60px;
  height: 60px;
}
.crancy-chatbox__heading--title {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}
.crancy-chatbox__heading--text {
  font-size: 16px;
  margin-top: 0;
}
.crancy-chatbox__incoming-chat {
  background: #edf2f7;
  padding: 12px;
  border-radius: 0px 8px 8px 8px;
}
.crancy-chatbox__chat {
  display: flex;
  gap: 15px;
  justify-content: left;
}
span.crancy-chatbox__typing {
  color: #9aa2b1;
  color: #9aa2b1;
  font-weight: 400;
  font-size: 16px;
}
.crancy-chatbox__time-two {
  font-size: 14px;
  color: #9aa2b1;
  min-width: 80px;
}
.crancy-chatbox__chat .crancy-chatbox__author-img img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
}
.crancy-chatbox__btn {
  font-size: 20px;
  cursor: pointer;
}
.crancy-chatbox__btn a {
  color: #9aa2b1;
}
.crancy-chatbox__btn a:hover {
  color: #0a82fd;
}
.crancy-chatbox__imoji {
  display: flex;
  gap: 8px;
}
.crancy-chatbox__btn svg {
  fill: #9aa2b1;
}
.crancy-chatbox__btn:hover svg {
  fill: #0a82fd;
}
.crancy-chatbox__incoming {
  margin: 0 40px;
}
.crancy-chatbox__imoji img {
  max-width: 30px;
}
.crancy-chatbox__incoming-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 40px;
}
.crancy-chatbox__incoming-text {
  margin: 0;
  font-size: 16px;
  color: #2f3032;
}
.crancy-chatbox__archive-date {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(83, 86, 251, 0.16);
  margin: 0 40px;
}
.crancy-chatbox__archive-time {
  position: relative;
  background: #fff;
  bottom: -10px;
  padding: 0 20px;
  color: #2f3032;
  margin-top: -10px;
  font-size: 14px;
}
.crancy-chatbox__new-message {
  margin: 100px 40px 40px;
}
.crancy-chatbox__form {
  border-radius: 30px;
  position: relative;
}
.crancy-chatbox__form-inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #eeeff2;
  border-radius: 8px;
}
.crancy-chatbox__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background: #fafafa;
  padding: 10px 20px;
}
.crancy-chatbox__button-inline {
  display: flex;
  align-items: center;
}
.crancy-chatbox__form-inner textarea {
  width: 100%;
  height: 80px;
  border-radius: 4px;
  padding: 20px;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  resize: block;
  resize: none;
}
.crancy-chatbox__button-inline__single {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #eeeff2;
  display: flex;
  align-items: center;
  gap: 15px;
}
.crancy-chatbox__button-inline__single:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.crancy-chatbox__submit-btn {
  background: #0a82fd;
  height: 42px;
  display: flex;
  gap: 10px;
  color: #fff;
  border-radius: 6px;
  border: none;
  padding: 0 16px;
  align-items: center;
  font-weight: 400;
}
.crancy-chatbox__submit-btn:hover {
  background: #303133;
  color: #fff;
}
.crancy-chatbox__main-content {
  display: flex;
  align-items: end;
  gap: 10px;
}
.crancy-header__blink {
  font-weight: 400;
}
.crancy-header__form--chatbox .search-btn {
  background: transparent;
}
.crancy-header__form--chatbox input {
  background: #e6f3ff;
}
.crancy__item-group__title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.crancy__item {
  background: #fff;
  padding: 35px;
  border-radius: 15px;
  box-shadow: 0 9px 95px 0 #0000000d;
}
.crancy__item-title {
  font-size: 26px;
  margin: 0;
}
.crancy__item-text {
  font-size: 16px;
  margin: 0;
}
.crancy__item-label {
  font-size: 16px;
  display: block;
  letter-spacing: 0.025em;
  margin-bottom: 8px;
}
.crancy__item-input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 30px !important;
  background-color: #fafafa;
}
.crancy__item-input:hover {
  border-color: #0a82fd;
}
.crancy__item-input.crancy__item-input__arrow {
  padding-right: 105px !important;
}
.crancy__item-form--group {
  position: relative;
}
.mg-top-form-20 {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}
.ntfmax__item-radio--group {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}
.crancy__item-input.crancy__item-textarea {
  height: 200px;
  line-height: initial;
  padding-top: 15px !important;
  border-radius: 20px !important;
}
.ntfmax__item-radio--group label {
  display: flex;
  flex-direction: column;
  background: transparent;
  margin: 0;
  padding: 10px;
  width: 122px;
  height: 130px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e4fe;
  border-radius: 0.375rem;
  cursor: pointer;
}
.item-input-icon {
  width: 68px;
  height: 68px;
  background: #f2e8fa;
  line-height: 68px;
  text-align: center;
  border-radius: 100%;
}
.ntfmax__item-radio--group img {
  width: 30px;
  margin-bottom: 20px;
  height: 35px;
}
.ntfmax__item-radio-title {
  color: #2f3032;
  font-size: 16px;
  font-weight: 400;
}
.crancy__item-sublabel {
  display: block;
  color: #9aa2b1;
  font-size: 16px;
  font-weight: 400;
}

.crancy__item-label--inline {
  color: #9aa2b1;
  font-weight: 400;
  margin-left: 10px;
}
.crancy__item-form--group--line {
  display: flex;
  gap: 40px;
  align-items: center;
}

.crancy__item-fee-text {
  margin: 0;
}
.crancy__item-fee {
  margin-top: 10px;
}

.form-select.crancy__item-form--select {
  position: absolute;
  right: 0;
  width: 100px;
  top: 45px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  height: 40px;
}
.crancy__item-fee-text b {
  font-weight: 400;
  font-size: 16px;
  color: #2f3032;
}

.crancy__item-form--unlock {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-top: 10px;
  justify-content: space-between;
}
.crancy__item-unlock--icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #e6e6e6;
  text-align: center;
  border-radius: 100%;
  min-width: 60px;
  min-height: 60px;
}

.crancy__item-label--head {
  font-size: 20px;
  margin-bottom: 8px;
}
.crancy__item-fee-text {
  font-size: 16px;
  color: #9aa2b1;
}
.ntfmax__item-radio--group label i {
  color: #858d98;
  font-size: 25px;
}
.ntfmax__item-radio--group--icon {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ntfmax__item-radio--group--icon img {
  width: 50px;
  height: 50px;
  margin-bottom: 2px;
}
.ntfmax__item-radio--collection label {
  width: 100px;
  height: 100px;
}
.col-lg-5.col-12.crancy-pcolumn__one {
  width: 425px;
}
.crancy__item-form--main {
  border-left: 1px solid rgba(83, 86, 251, 0.16);
  padding-left: 40px;
  padding-bottom: 30px;
}
.crancy__item-box {
  border-bottom: 1px solid rgba(83, 86, 251, 0.16);
}

.crancy-chatbox__outgoing {
  margin-left: 115px;
  margin-top: 45px;
}
.crancy-chatbox__outgoing .crancy-chatbox__incoming-chat {
  background: #0a82fd;
  color: #fff;
  border-radius: 10px 0 10px 10px;
}
.crancy-chatbox__outgoing .crancy-chatbox__time {
  text-align: right;
}
.crancy-chatbox__outgoing .crancy-chatbox__chat .crancy-chatbox__incoming-text {
  color: #fff;
}
.crancy-chatbox__outgoing .crancy-chatbox__chat {
  justify-content: right;
}
.crancy-chatbox__only-text {
  margin-left: 75px;
}
.crancy-chatbox__right-bar {
  cursor: pointer;
}

.crancy__item-form--main input,
.crancy__item-form--main textarea {
  border: 1px solid #e3e4fe;
}
.crancy-chatbox__sidebar--inbox .crancy-chatbox__inner {
  padding-top: 20px;
  padding-bottom: 20px;
}
.crancy-chatbox__author.crancy-chatbox__email .crancy-chatbox__author-img svg {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #e7f3ff;
  line-height: 40px;
  text-align: center;
  padding: 8px;
  fill: #0a82fd;
}
.crancy-chatbox__stared {
  color: #fec008;
  cursor: pointer;
}
.crancy-chatbox__author.crancy-chatbox__email .crancy-chatbox__author-title {
  font-size: 18px;
  font-weight: 500;
}
.crancy-chatbox__author.crancy-chatbox__email .crancy-chatbox__author-title a {
  font-weight: 500;
}
.crancy-chatbox__author.crancy-chatbox__email .crancy-chatbox__author-img img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 4px;
}
.crancy-chatbox__right.crancy-chatbox__right__message {
  gap: 12px;
}
.crancy-chatbox__time.crancy-chatbox__message {
  color: #0a82fd;
}
.crancy-chatbox__stared.crancy-chatbox__stared--no {
  color: #9aa2b1;
}
.crancy-chatbox__author-meta {
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
.crancy-chatbox__author-meta span {
  color: #0a82fd;
  font-weight: 500;
}
/* Chat Inbox Header */
.crancy-chatbox__header {
  background: #e4f2ff;
  border-radius: 11px 11px 0px 0px;
}
.crancy-email-menu {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 25px;
  flex-wrap: wrap;
}
.crancy-email-menu li a {
  padding: 22px 0 18px;
  font-weight: 400;
  font-weight: 400;
  font-size: 18px;
  color: #9aa2b1;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
}
.crancy-email-menu li:hover a,
.crancy-email-menu li.crancy-email-menu__active a {
  border-bottom-color: #0a82fd;
}
.crancy-email-menu__count {
  position: relative;
  top: -5px;
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  padding: 0;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font-size: 13px;
  right: 0;
}
.crancy-email-menu li a svg {
  fill: #9aa2b1;
}
.crancy-email-menu li.crancy-email-menu__active a,
.crancy-email-menu li:hover a,
.crancy-email-menu li.crancy-email-menu__active svg,
.crancy-email-menu li:hover svg {
  color: #0a82fd;
  fill: #0a82fd;
}
.crancy-chatbox.crancy-chatbox--explore {
  padding-bottom: 30px;
  padding-right: 30px;
}
.crancy-ebuttons {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 60px;
  flex-wrap: wrap;
}
.crancy-chatbox__explore.crancy-chatbox__explore--message {
  border: 1px solid #e8f4ff;
  border-radius: 12px;
  padding: 20px;
  justify-content: initial;
}
.crancy-chatbox__detail h1,
.crancy-chatbox__detail h2,
.crancy-chatbox__detail h3,
.crancy-chatbox__detail h4,
.crancy-chatbox__detail h5,
.crancy-chatbox__detail h6 {
  margin-bottom: 20px;
}
.crancy-chatbox__detail p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #2f3032;
  line-height: 28px;
}
.crancy-chatbox__detail p a {
  display: inline;
  font-weight: 400;
}
.crancy-chatbox__detail p:last-child {
  margin: 0;
}
.crancy-attachments {
  padding-top: 25px;
  margin-top: 40px;
  border-top: 1px solid #e4f2ff;
}
.crancy-attachments__heading a {
  color: #0a82fd;
  font-weight: 400;
}
.crancy-attachments__heading {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 400;
  font-size: 18px;
}
.crancy-attachments__attached {
  display: flex;
  align-items: center;
  gap: 20px;
}
.crancy-attachments__single {
  width: 250px;
}
.crancy-attachments__single--head {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.crancy-attachments__single--head a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #0a82fd;
  opacity: 0;
  visibility: hidden;
}
.crancy-attachments__single:hover .crancy-attachments__single--head a {
  opacity: 1;
  visibility: visible;
}
.crancy-attachments__single--head:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: red;
  background: #2f3032;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.crancy-attachments__single:hover .crancy-attachments__single--head::before {
  opacity: 0.9;
  visibility: visible;
}
.crancy-attachments__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.crancy-attachments__size {
  color: #2f3032;
}

.crancy-chatbox__message--author span {
  color: #9aa2b1;
}
.crancy-chatbox__message--author h4 {
  color: #2f3032;
  font-weight: 500;
  margin: 5px 0 0;
}

.crancy-chatbox__right__message {
  display: flex;
  gap: 25px;
  justify-content: right;
}
.crancy-chatbox__delete a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
}
.crancy-chatbox__main.crancy-chatbox__message {
  border-top: 0px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.crancy-chatbox__detail h1,
.crancy-chatbox__detail h2,
.crancy-chatbox__detail h3,
.crancy-chatbox__detail h4,
.crancy-chatbox__detail h5,
.crancy-chatbox__detail h6 {
  font-weight: 500;
}

.crancy-chatbox__message--header {
  padding: 0 25px;
}
.crancy-chatbox__sidebar.crancy-chatbox__sidebar--inbox {
  padding-top: 25px;
}

/* Crancy Ticket Card */
.crancy-ticket-card {
  padding: 25px 25px 0;
  border-radius: 12px;
}
.crancy-ticket-card img {
  margin-top: 30px;
}
.crancy-ticket-card__title {
  color: #fff;
  font-size: 26px;
  margin-bottom: 10px;
}

.crancy-ticket-card__img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-ticket-card .crancy-btn {
  padding: 10px 16px;
  font-size: 16px;
}
.crancy-theader {
  background: #e4f2ff;
  padding: 25px 40px;
  border-radius: 12px 12px 0px 0px;
}
.crancy-theader__title {
  font-size: 18px;
}
.crancy-tdetails .crancy-chatbox__explore {
  background: #fff;
  border-radius: 0 0 12px 12px;
  padding: 30px 40px;
}
.crancy-tinfo {
  background: #fff;
  border-radius: 12px 12px 0px 0px;
}
.crancy-tinfo__heading {
  background: #0a82fd;
  padding: 20px 25px;
  color: #fff;
  border-radius: 12px 12px 0px 0px;
}
.crancy-tinfo__body {
  padding: 30px 25px 25px;
}
.crancy-tinfo__list {
  display: flex;
  flex-direction: column;
}
.crancy-tinfo__list li {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: ;
  border-bottom: 1px dashed #e4f2ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crancy-tinfo__title {
  min-width: 115px;
  color: #9aa2b1;
}
.crancy-tinfo__button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-tinfo__list li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.crancy-tinfo__title--value {
  text-align: right;
}
.crancy-chatbox__outgoing.crancy-chatbox__outgoing--email li {
  width: 100%;
  margin: 0;
}
.crancy-chatbox__outgoing.crancy-chatbox__outgoing--email {
  margin: 0;
}
.crancy-chatbox__outgoing.crancy-chatbox__outgoing--email
  .crancy-chatbox__incoming-chat {
  background: #e4f2ff;
  margin-left: 45px;
}
.crancy-chatbox__outgoing.crancy-chatbox__outgoing--email
  .crancy-chatbox__chat
  .crancy-chatbox__incoming-text,
.crancy-chatbox__outgoing.crancy-chatbox__outgoing--email
  .crancy-chatbox__chat
  li {
  color: #2f3032;
  line-height: 28px;
  font-size: 16px;
}

.crancy-chatbox__outgoing.crancy-chatbox__outgoing--email
  .crancy-chatbox__chat
  ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}
.crancy-chatbox__outgoing.crancy-chatbox__outgoing--email
  .crancy-chatbox__chat
  li
  a {
  font-weight: 400;
}
.crancy-chatbox__main-content span {
  color: #9aa2b1;
}
.crancy-chatbox__label {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}
.crancy-chatbox__label h4,
.crancy-chatbox__label a {
  margin: 0;
  font-weight: 400;
}
.crancy-chatbox__withdate {
  display: flex;
  gap: 50px;
}
.crancy-chatbox__withdate {
  display: flex;
}
.crancy-chatbox__withdate time {
  min-width: 10%;
}
@media only screen and (max-width: 1550px) {
  .crancy-chatbox__author-img img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-chatbox__form-inner input {
    padding-right: 54%;
  }
  .col-lg-5.col-12.crancy-pcolumn__one {
    width: 38%;
  }
  .crancy-chatbox__one {
    width: 38%;
  }
  .crancy-chatbox__two {
    width: 62%;
  }
  .crancy-header__form--inbox {
    width: 200px;
  }
  .crancy-chatbox__heading--title {
    margin: 0;
    font-size: 20px;
  }
  .crancy-chatbox__author-title {
    font-size: 18px;
  }
  .crancy-chatbox__sidebar.crancy-chatbox__sidebar--v3
    .crancy-chatbox__author-title {
    font-size: 15px;
  }
  .crancy-chatbox__author-desc {
    line-height: 22px;
  }
  .crancy-chatbox__incoming-list li {
    width: 90%;
  }
  .crancy-chatbox__outgoing {
    margin-left: 50px;
  }
  .crancy-tinfo__body {
    padding: 30px 15px 25px;
  }
  .crancy-tinfo__title--value {
    font-size: 14px;
  }
  .crancy-chatbox__withdate {
    gap: 25px;
  }
  .crancy-chatbox__withdate time {
    min-width: 16%;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-chatbox__author-desc {
    display: none;
  }
  .crancy-chatbox__button {
    position: relative;
  }
  .crancy-chatbox__form-inner {
    flex-direction: column;
  }
  .crancy-chatbox__form-inner input {
    padding: 0 30px;
  }
  .crancy__item-form--main {
    margin-top: 30px;
  }
  .col-lg-5.col-12.crancy-pcolumn__one {
    width: 38%;
  }
  .crancy-chatbox__one {
    width: 38%;
  }
  .crancy-chatbox__two {
    width: 62%;
  }
  .crancy-chatbox__explore {
    margin-right: 25px;
  }
  .crancy-header__form--inbox {
    display: none;
  }
  .crancy-chatbox__heading--title {
    margin: 0;
    font-size: 20px;
  }
  .crancy-chatbox__author-title {
    font-size: 18px;
  }
  .crancy-chatbox__author-desc {
    line-height: 22px;
  }
  .crancy-chatbox__incoming-list li {
    width: 100%;
  }
  .crancy-chatbox__outgoing {
    margin-left: 50px;
  }
  .crancy-chatbox__main {
    padding: 0;
  }
  .crancy-chatbox__author {
    gap: 10px;
  }
  .crancy-chatbox__author.crancy-chatbox__email {
    flex-direction: column;
    align-items: start;
  }
  .crancy-tinfo__list li {
    flex-direction: column;
    align-items: start;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .crancy-tinfo__title--value {
    text-align: left;
  }
  .crancy-chatbox__sidebar.crancy-chatbox__sidebar--inbox
    .crancy-element__form {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-chatbox__list li {
    padding-right: 20px;
    padding-left: 20px;
  }
  .crancy-chatbox__two {
    margin-top: 30px;
  }
  .crancy-chatbox__button {
    position: relative;
  }
  .crancy-chatbox__form-inner {
    flex-direction: column;
  }
  .crancy-chatbox__form-inner input {
    padding: 0 30px;
  }
  .ntfmax__item-radio--group--icon img {
    width: 30px;
    height: 30px;
    margin-bottom: 2px;
  }
  .crancy__item-form--main {
    margin-top: 30px;
  }
  .crancy-chatbox__outgoing {
    margin-left: 40px;
  }
  .crancy-chatbox__incoming {
    margin: 0 25px;
  }

  .crancy__file-upload {
    width: 100%;
  }
  .col-lg-5.col-12.crancy-pcolumn__one {
    width: 100%;
  }
  .crancy__item-form--main {
    padding-left: 0;
    border: none;
  }

  .crancy-chatbox__one,
  .crancy-chatbox__two {
    width: 100%;
  }
  .crancy-chatbox__author-title {
    font-size: 15px;
  }

  .crancy-chatbox__incoming-list li {
    width: 100%;
  }
  .crancy-chatbox__only-text {
    margin-left: 20px;
  }
  .crancy-header__form-inner {
    display: none;
  }
  .crancy-chatbox__explore-head {
    padding: 15px;
  }
  .crancy-chatbox__heading--title {
    font-size: 18px;
  }
  .crancy-chatbox__heading--text {
    margin-top: 5px;
    font-size: 15px;
  }

  .crancy-chatbox__main {
    padding: 0;
  }

  .crancy-attachments__attached {
    flex-direction: column;
    align-items: start;
  }
  .crancy-ebuttons {
    margin-top: 40px;
  }
  .crancy-chatbox__withdate {
    gap: 25px;
  }

  .crancy-chatbox.crancy-chatbox--explore {
    padding-right: 0;
  }
  .crancy-chatbox__explore.crancy-chatbox__explore--message {
    margin: 0 20px;
  }
  .crancy-attachments__heading {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
  .crancy-ebuttons .crancy-btn {
    padding: 10px 15px;
  }
  .crancy-chatbox__outgoing.crancy-chatbox__outgoing--email
    .crancy-chatbox__incoming-chat {
    width: 100%;
  }
  .crancy-chatbox__explore.crancy-chatbox__explore--message {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .crancy-email-menu {
    display: block;
    padding: 15px 10px;
  }
  .crancy-email-menu li {
    display: inline-block;
  }
  .crancy-email-menu li a {
    padding: 8px 10px;
  }
  .crancy-email-menu__count {
    top: 0;
    right: 0;
  }

  .crancy-chatbox__withdate {
    gap: 25px;
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-email-menu {
    gap: 20px;
  }
}
/*======================================
   End Chat & Email CSS
========================================*/

/*======================================
	To-do List CSS
========================================*/
.crancy-todolist {
  background: #ffffff;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
  padding: 25px;
}

.crancy-todolist__sidebar {
  padding: 25px;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
}
.crancy-todolist__title {
  color: #2f3032;
  font-size: 18px;
}
.crancy-element__form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.crancy-element__form form {
  width: 100%;
}
.crancy-element__form .search-btn {
  background: transparent;
  padding: 0;
  margin: 0;
}
.crancy-todolist__menu--heading {
  color: #0a82fd;
  font-size: 18px;
  margin-bottom: 20px;
}
.crancy-todolist__menu--list__star i {
  color: #fec008;
}
.crancy-todolist__menu--list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.crancy-todolist__menu--list li a {
  display: flex;
  flex-direction: ;
  align-items: center;
  color: #9aa2b1;
  font-weight: 400;
  font-size: 18px;
  gap: 14px;
}
.crancy-todolist__menu--list li svg {
  fill: #9aa2b1;
}
.crancy-todolist__menu--list li:hover a,
.crancy-todolist__menu--list li:hover svg {
  color: #0a82fd;
  fill: #0a82fd;
}
.crancy-todolist__task--group {
  display: flex;
  align-items: center;
  background: #2f3032;
  border-radius: 4px;
  justify-content: space-between;
  padding: 10px 15px;
}
.crancy-todolist__heading {
  color: #fff;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
}
.crancy-todolist__badge {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Crancy Single Task */
.crancy-single__task {
  padding: 18px;
  background: #f8fbff;
  border: 1px solid #e4f2ff;
  border-radius: 4px;
}
.crancy-single__task--toggle {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid #e4f2ff;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-single__task--toggle:hover i {
  color: #0a82fd;
}
.crancy-single__task--toggle svg {
  fill: #9aa2b1;
}
.crancy-single__task--toggle i {
  color: #9aa2b1;
}
.crancy-single__task--label {
  color: #9aa2b1;
  font-size: 14px;
  font-weight: 400;
}
.crancy-single__task--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.crancy-single__task--progress--days {
  font-size: 14px;
}
.crancy-single__task--title a {
  color: #2f3032;
  font-size: 16px;
}
.crancy-single__task--title a:hover {
  color: #0a82fd;
}
.crancy-single__task--v2 .crancy-single__task--title a:hover {
  color: #ef5da8;
}
.crancy-single__task--v3 .crancy-single__task--title a:hover {
  color: #27ae60;
}

.crancy-single__task--progress {
  margin-bottom: 20px;
}
.crancy-single__task--footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crancy-single__task--date {
  background: #dbedff;
  font-size: 14px;
  padding: 3px 22px;
  border-radius: 100px;
  color: #0a82fd;
}
.crancy-single__task--icon {
  color: #fec008;
}
.crancy-single__task--addnew {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f8fbff;
  justify-content: center;
  border-radius: 4px;
  padding: 10px 0;
  color: #2f3032;
  border: 1px dashed rgba(10, 130, 253, 0.8);
}
.crancy-single__task--addnew:hover {
  color: #0a82fd;
}
.crancy-single__task.crancy-single__task--v2 {
  background: #fef9fc;
  border-color: #dbe8f5;
}
.crancy-single__task.crancy-single__task--v3 {
  background: #f6fcf9;
  border-color: #dbe8f5;
}
.crancy-single__task--addnew.crancy-single__task--addnew-v2 {
  background: #f8fbff;
  border-color: #ef5da8;
}
.crancy-single__task--addnew.crancy-single__task--addnew-v2:hover {
  color: #ef5da8;
}
.crancy-single__task--addnew.crancy-single__task--addnew-v3 {
  background: #f8fbff;
  border-color: #27ae60;
}
.crancy-single__task--addnew.crancy-single__task--addnew-v3:hover {
  color: #27ae60;
}
/* Project Detail */
.crancy-project-preview__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.crancy-project-preview__list li {
  display: flex;
  align-items: center;
  gap: 20px;
}
.crancy-project-preview__list li {
  color: #9aa2b1;
}
.crancy-project-preview__list li span {
  min-width: 120px;
  color: inherit;
}
.crancy-project-preview__list li b {
  color: #2f3032;
  font-weight: 400;
}
.crancy-project-preview__task {
  font-weight: 500;
}
.crancy-project-preview__heading {
  font-size: 24px;
}
/*======================================
	End To-do List CSS
========================================*/

/*======================================
	Profiles CSS
========================================*/
.crancy-upcontent {
  background: #fff;
  padding: 30px;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
}
.crancy-upcard {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
}
.crancy-upcard__thumb img {
  display: inline-block;
  border-radius: 100%;
  border: 4px solid #e4f2ff;
  min-width: 145px;
  min-height: 145px;
}
.crancy-upcard__heading {
  margin-top: 25px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4f2ff;
}
.crancy-upcard__title {
  font-size: 24px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
}
.crancy-upcard__location {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #0a82fd;
}
.crancy-upcard__info {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}
.crancy-upcard__location svg {
  fill: #0a82fd;
}
.crancy-upcard__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.crancy-upcard__list li {
  font-size: 18px;
  color: #2f3032;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
.crancy-upcard__list li b {
  min-width: 120px;
  display: inline-block;
  font-weight: 400;
}
.crancy-upcard__list--label {
  background: #e7f3ff;
  padding: 8px 12px;
  color: #0a82fd !important;
  border-radius: 4px;
}
.crancy-upcard__list li span {
  margin-left: 10px;
}
.crancy-upcard__list li span {
  color: #9aa2b1;
}
.crancy-upcard__social {
  display: flex;
  gap: 15px;
  margin-top: 30px;
}
.crancy-pcats {
  margin-top: 10px;
}
.crancy-pcats__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.crancy-pcats__bar.crancy-pcats__bar--v2 {
  justify-content: center;
}
.crancy-pcats__list {
  display: flex;
  align-items: center;
  flex-direction: initial;
  gap: 16px;
}
.crancy-pcats__list a {
  color: #0a82fd;
  font-weight: 400;
  font-size: 18px;
  position: relative;
  margin: 0;
  border: none;
  line-height: initial;
  padding: 12px 25px;
  border-radius: 4px !important;
  background: #e7f3ff;
  display: flex;
  align-items: center;
  gap: 5px;
}
.crancy-pcats__list a svg {
  fill: #0a82fd;
  width: 18px;
  height: 18px;
}
.crancy-tasksingle__text {
  font-size: 14px;
}
.crancy-pcats__list a {
  letter-spacing: 0.025em;
}
.crancy-pcats__list a.active,
.crancy-pcats__list a:hover {
  color: #fff !important;
  background: #0a82fd !important;
}
.crancy-pcats__list a.active svg,
.crancy-pcats__list a:hover svg {
  fill: #fff;
}
.crancy-upcontent__text p {
  line-height: 28px;
}
.crancy-tasksingle__group {
  margin: 0;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.crancy-tasksingle__group span {
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.crancy-tasksingle__group a:hover span {
  opacity: 1;
  visibility: visible;
}
.crancy-tasksingle__group a:nth-child(2) {
  left: -12px;
  margin-right: -12px;
}
.crancy-tasksingle__group a:nth-child(3) {
  left: -12px;
  margin-right: -12px;
}
.crancy-tasksingle__group a:nth-child(4) {
  left: -12px;
  margin-right: -12px;
}
.crancy-tasksingle__group a:nth-child(5) {
  left: -12px;
}
.crancy-tasksingle__group img {
  width: 100%;
  height: 100%;
}
.crancy-uactivity {
  border: 1px solid #e4f2ff;
  border-radius: 12px;
  padding: 15px 25px;
}
.crancy-uactivity__heading {
  font-size: 18px;
  color: #2f3032;
  font-weight: 500;
}
.crancy-uactivity__list {
  border-left: 1px dashed #e4f2ff;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.crancy-uactivity__list li {
  padding-left: 12px;
}
.crancy-uactivity__title {
  color: #2f3032;
  margin: 0;
  margin-bottom: 5px;
  font-size: 16px;
}
.crancy-uactivity__list li.crancy-uactivity__list__two::before {
  border-color: #ef5da8;
}
.crancy-uactivity__list li.crancy-uactivity__list__three::before {
  border-color: #27ae60;
}
.crancy-uactivity__list li.crancy-uactivity__list__four::before {
  border-color: #f2994a;
}
.crancy-uactivity__time {
  border-left: 1px solid #e4f2ff;
  color: #9aa2b1;
  padding-left: 5px;
  margin-left: 5px;
}
.crancy-uactivity__all {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.crancy-tasksingle {
  padding: 20px;
  border-radius: 4px;
}
.crancy-tasksingle__title {
  font-size: 18px;
  margin-bottom: 8px;
  line-height: 20px;
  font-weight: 500;
}
.crancy-tasksingle__title a {
  font-weight: 400;
}
.crancy-tasksingle__assign {
  margin-bottom: 8px;
  font-size: 14px;
}
.crancy-tasksingle__lmain {
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-tasksingle__label {
  margin-bottom: 15px;
  font-size: 14px;
  padding: 0px 18px;
  display: inline-block;
  border-radius: 4px;
}
.crancy-progress__in {
  background-color: #b2d8ff;
  color: #0a82fd;
}
.crancy-progress__done {
  background: #bae5cd;
  color: #27ae60;
}
.crancy-progress__hold {
  background: #ccccfa;
  color: #5d5fef;
}
.crancy-progress__cancel {
  background: #f9caca;
  color: #eb5757;
}

.crancy-tasksingle__group a {
  position: relative;
  width: 32px;
  height: 32px;
}
.crancy-tasksingle__group span {
  position: absolute;
  background: #0a82fd8c;
  top: 0;
  left: 0;
  min-width: max-content;
  padding: 0;
  color: #fff;
  border-radius: 5px;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
}
.crancy-upinner__column1 {
  width: 30%;
}
.crancy-upinner__column2 {
  width: 70%;
}
/* End Profile Overview */

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-pcats__list a {
    font-size: 16px;
    padding: 10px 15px;
  }
  .crancy-upcard__list {
    gap: 20px;
  }
  .crancy-upcard__thumb img {
    min-width: 115px;
    min-height: 115px;
    width: 115px;
    height: 115px;
  }
  .crancy-upcard__title {
    font-size: 20px;
  }
  .crancy-upcard__heading {
    margin-top: 15px;
  }
  .crancy-upcard__list li {
    text-align: left;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .crancy-upcard__list li b {
    display: block;
  }
  .crancy-upcard__list li span {
    color: #9aa2b1;
    margin: 0;
  }

  .crancy-psidebar__list {
    gap: 5px !important;
  }
  .crancy-ptabs__form-update {
    width: 250px !important;
    min-width: 250px;
    padding: 25px 20px 0 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-pcats__list a {
    font-size: 16px;
    padding: 10px 15px;
  }
  .crancy-upcard__list {
    gap: 10px;
  }
  .crancy-upcard__thumb img {
    min-width: 115px;
    min-height: 115px;
    width: 115px;
    height: 115px;
  }
  .crancy-upcard__title {
    font-size: 20px;
  }
  .crancy-upcard__heading {
    margin-top: 15px;
  }
  .crancy-upcard__list li {
    text-align: left;
    font-size: 15px;
  }
  .crancy-upcard__list li b {
    display: block;
    margin-bottom: 0px;
  }
  .crancy-upcard__list li span {
    color: #9aa2b1;
    margin: 0;
  }
  .crancy-pcats__list {
    gap: 10px;
  }
  .crancy-pcats__list a {
    font-size: 14px;
    padding: 10px;
  }
  .crancy-upcard {
    padding: 20px;
  }
  .crancy-personals__list {
    width: 18.5% !important;
  }
  .crancy-personals__content {
    width: 81.5% !important;
  }
  .crancy-ptabs__form-update {
    width: 200px !important;
    min-width: 200px !important;
    padding: 20px;
  }
  .crancy-upinner__column1,
  .crancy-upinner__column2 {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-upinner__column1,
  .crancy-upinner__column2 {
    width: 100%;
  }
  .crancy-pcats__list {
    margin-left: -5px;
    margin-top: -5px;
    display: block;
  }
  .crancy-pcats__list a {
    margin-left: 5px;
    display: inline-block;
    padding: 10px 10px;
    margin-top: 5px;
    font-size: 16px;
  }
  .crancy-pcats__list a:before {
    bottom: 0;
  }
  .crancy-upcard__list li {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .crancy-upcard__list li span {
    color: #9aa2b1;
    margin: 0;
  }
  .crancy-btn.crancy-btn__profile {
    padding: 5px 10px;
  }
  .crancy-btn.crancy-btn__share {
    width: 45px;
    font-size: 22px;
    height: 34px;
  }
}
/*======================================
	End Profiles CSS
========================================*/

/*======================================
   crancy Profiles CSS
========================================*/
.crancy__item-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
}
.crancy__item-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.crancy__item-switch--slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9aa2b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.crancy__item-switch--slide::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 0.5px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  top: 1.5px;
}
.crancy__item-unlock--first {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy__item-switch input:checked + .crancy__item-switch--slide {
  background-color: #27ae60;
}

.crancy-ptabs__notify-switch--two input:checked + .crancy__item-switch--slide {
  background-color: #27ae60;
}
.crancy__item-switch input:focus + .crancy__item-switch--slide {
  box-shadow: 0 0 1px #2196f3;
}

.crancy__item-switch input:checked + .crancy__item-switch--slide::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(22px);
}

/* Rounded sliders */
.crancy__item-switch--round {
  border-radius: 34px;
}

.crancy__item-switch--round:before {
  border-radius: 50%;
}

.crancy__item-button--group {
  display: flex;
  justify-content: right;
  margin-top: 30px;
  gap: 16px;
  align-items: center;
}

.crancy__item-button--cancel {
  background: transparent;
  padding: 0;
  border: none;
  color: #eb5757;
  border-bottom: 1px solid;
  border-radius: 0px;
  padding: 0 !important;
  line-height: initial;
  height: initial !important;
  font-size: 18px;
}
.crancy__item-button--single {
  padding: 14px 22px !important;
}
.crancy__item-button--cancel {
  padding: 0 !important;
}

.crancy__file-title {
  text-align: center;
  font-size: 26px;
  margin: 20px 0 0;
}
.crancy__file-upload--img {
  max-width: 70px;
}
.crancy__item-heading {
  margin-bottom: 20px;
}

.crancy-userprofile__user {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.crancy-userprofile__thumb {
  max-width: 92px;
  background: #e4f2ff;
  border-radius: 50%;
  padding: 4px;
  top: -46px;
  position: relative;
  margin-bottom: -45px;
}
.crancy-userprofile__thumb img {
  border-radius: 50%;
}
.crancy-userprofile__info-title {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.crancy-userprofile .crancy-userprofile__info-text {
  padding-right: 20%;
}

.crancy-grid-list a {
  background: #cee6ff;
  width: 48px;
  height: 48px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.crancy-grid-list a:hover svg {
  fill: #fff;
}
.crancy-grid-list a:hover {
  background: #0a82fd;
}
.crancy-team-meta {
  display: flex;
  justify-content: right;
  gap: 15px;
  align-items: center;
}
.crancy-team-meta__single .crancy-btn {
  display: ;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.crancy-team-meta__single .crancy-btn i {
  font-size: 22px;
}
.crancy-team-meta__single svg {
  fill: #0a81fc;
}

.crancy-teams {
  padding: 30px;
  background: #fff;
  margin-top: 35px;
  border-radius: 4px;
}

.crancy-header__form--v2 .search-btn {
  background: #f1c84c;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 0 4px 4px 0px;
  position: absolute;
  right: 0;
}
.crancy-header__form--v2 .crancy-header__form-inner input {
  padding: 0 55px 0 20px;
  height: 52px;
}
.crancy-header__form-inner.crancy-header__form-inner__profile {
  padding: 0;
  max-width: 375px;
}
.crancy-header__form--v2 .crancy-header__form-inner .search-btn {
  height: 100%;
}
.crancy-userprofile {
  background: #fff;
  padding: 0;
  border: 1px solid #e4f2ff;
  border-radius: 8px;
  overflow: hidden;
}
.crancy-userprofile img {
  border-radius: 0px !important;
}
.crancy-userprofile__location {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.crancy-userprofile__info-text {
  color: #0a82fd;
  font-size: 14px;
  line-height: 20px;
  padding-right: 30px;
}
.crancy-userprofile__info-text a {
  color: #0a82fd;
  font-weight: 400;
}
.crancy-userprofile__right {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: right;
  position: absolute;
  top: 0;
  right: 30px;
  height: 100%;
}
.crancy-userprofile__content {
  padding: 0 20px 20px;
  width: 100%;
}
.crancy-achievement__btn {
  background: #e7f3ff;
  width: 100%;
  color: #0a82fd;
  font-weight: 400;
  text-align: center;
  padding: 11px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 4px;
  margin-top: 15px;
}
.crancy-achievement__btn:hover {
  background: #0a82fd;
  color: #fff;
}
.crancy-achievement__btn svg {
  fill: #0a82fd;
}
.crancy-achievement__btn:hover svg {
  fill: #fff;
}

.crancy-achievement {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  justify-content: space-between;
}
.crancy-achievement__single {
  display: flex;
  align-items: center;
  gap: 10px;
}

.crancy-achievement__title {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0;
}
.crancy-achievement__title span {
  color: #9aa2b1;
  font-size: 14px;
  font-weight: 400;
}
.crancy-achievement__content {
  font-size: 18px;
  color: #2f3032;
}
.crancy-btn.crancy-btn__share {
  padding: 0;
  width: 52px;
  font-size: 22px;
  height: 48px;
  line-height: 52px;
  background: #fff;
  color: #2f3032;
  display: flex;
  justify-content: center;
}
.crancy-btn.crancy-btn__share svg {
  fill: #0a82fd;
}
.crancy-btn.crancy-btn__share:hover {
  background: #0a82fd;
}
.crancy-btn.crancy-btn__share:hover svg {
  fill: #fff;
}
.crancy-userprofile__meta {
  display: flex;
  align-items: center;
  color: #82829a;
  gap: 5px;
  margin-right: 18px;
  padding-right: 18px;
  border-right: 1px solid rgba(0, 0, 0, 0.33);
  font-size: 20px;
}
.crancy-userprofile__number {
  font-weight: 700;
  color: #2f3032;
}
.crancy-userprofile__meta:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.crancy-btn.crancy-btn__profile {
  padding: 12px 20px;
  display: inline-block;
  line-height: 1.5rem;
  font-size: 16px;
  font-weight: 400;
}

.crancy-personals__list {
  width: 23.5%;
}
.crancy-personals__content {
  width: 76.5%;
}
.crancy-ptabs__stext b {
  color: #000;
  font-weight: 400;
}

.crancy-personals__title {
  font-size: 20px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .crancy-header__form-inner.crancy-header__form-inner__profile {
    width: 100% !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .crancy-teams__title {
    text-align: center;
  }
  .crancy-team-meta {
    justify-content: center;
  }
}

.crancy-psidebar__heading {
  color: #fafafa;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 12px;
}
.crancy-psidebar a {
  border: none;
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.025em;
}
.crancy-psidebar__icon {
  width: 32px;
  position: relative;
}
.crancy-psidebar__icon {
  font-size: 16px;
}
.crancy-psidebar__icon svg {
  fill: #fff;
}
.crancy-psidebar a {
  fill: #fff;
  background: transparent;
  padding: 13px 15px;
  border-radius: 4px;
}
.crancy-psidebar a:hover,
.crancy-psidebar a.active {
  background: transparent;
  fill: #fff;
  background: #3b9bfd;
}
.crancy-psidebar {
  background: #0a82fd;
  padding: 25px 30px;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.crancy-psidebar__list {
  display: flex;
  gap: 15px;
  flex-direction: column;
}
.crancy-ptabs__separate {
  display: flex;
  align-items: first baseline;
  justify-content: space-between;
  gap: 30px;
}
.crancy-ptabs__form-main {
  width: 100%;
}
.crancy-ptabs__social {
  border-top: 1px solid #e3e4fe;
  padding-top: 25px;
}
.crancy-ptabs__social-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crancy-ptabs__social-connect a {
  margin: 0;
  color: #33aaf2;
  font-size: 20px;
  border-bottom: 1px solid;
}
.crancy-ptabs__social-icon {
  width: 44px;
  height: 44px;
  line-height: 44px;
  display: inline-block;
  text-align: center;
  background: #33aaf23d;
  color: #33aaf2;
  border-radius: 100%;
  font-size: 20px;
}
.crancy-ptabs__social-name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 400;
}
.crancy-ptabs__social-list {
  margin: 12px 0;
}

.crancy-ptabs__verified {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  gap: 40px;
}
.crancy-ptabs__verified-button {
  min-width: 29%;
}

.crancy-btn.crancy-ptabs__verified-btn {
  background: #27ae60;
}
.crancy-ptabs .crancy__item-input {
  padding: 0 15px !important;
}
.crancy-paymentm__name {
  display: flex;
  gap: 20px;
  align-items: center;
}
.crancy-paymentm__list {
  padding-top: 30px;
}
.crancy-paymentm__manage-btn button {
  padding: 15px 20px;
}
.crancy-paymentm__manage-btn button:hover {
  background: #2f3032 !important;
}
.crancy-paymentm__single {
  border-bottom: 1px solid rgba(83, 86, 251, 0.16);
  padding-bottom: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crancy-paymentm__single:last-child {
  padding: 0;
  margin: 0;
  border: none;
}
.crancy-paymentm__badge {
  font-size: 18px;
  margin-top: 10px;
  display: block;
  font-weight: 500;
}
.crancy-paymentm__badge.crancy__ok {
  color: #27ae60;
}
.crancy-paymentm__badge.crancy__error {
  color: #eb5757;
}
.crancy-paymentm__text {
  margin: 0;
  font-size: 18px;
}
.crancy-paymentm__title {
  margin: 0 0 10px;
  font-size: 20px;
}
.crancy-paymentm__icon {
  width: 120px;
  height: 120px;
  text-align: center;
  background: #e4f2ff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-paymentm__icon img {
  max-width: 50px;
}
.crancy-paymentm__single.crancy-paymentm__single--notify {
  padding-bottom: 20px;
  margin-bottom: 20px;
  gap: 20px;
}
.crancy-paymentm__single.crancy-paymentm__single--notify:last-child {
  padding: 0;
  margin: 0;
}
.crancy__item-button--group.crancy__ptabs-bottom.v2 {
  justify-content: flex-start;
}

.crancy__ptabs-bottom.v2 button {
  padding: 16px 30px;
  font-size: 18px;
}
.crancy-pptabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  gap: 20px;
}
.crancy-header__form-profile {
  width: 510px;
}
.crancy-pptabs__main .crancy-sidebar_btn {
  font-size: 18px;
  border: none;
}
.crancy-pptabs__main .crancy-table__product-desc {
  color: #2f3032;
  font-size: 16px;
}

.crancy-table__profile-activity .crancy-table__head tr th,
.crancy-table__profile-activity .crancy-table__body tr td,
.crancy-table__profile-activity .crancy-table__body .crancy-table__text {
  text-align: left !important;
}
.crancy-table__profile-activity .crancy-table__body .crancy-table__amount {
  justify-content: initial;
}
.crancy-table__profile-activity .crancy-table__column-2 {
  width: 30%;
}
.crancy-table__profile-activity .crancy-table__column-1 {
  width: 6%;
}
.crancy-table__profile-activity .crancy-table__column-3,
.crancy-table__profile-activity .crancy-table__column-4,
.crancy-table__profile-activity .crancy-table__column-7 {
  width: 12%;
}
.crancy-table__profile-activity .crancy-table__column-6 {
  width: 5%;
}
.crancy-table__profile-activity .crancy-table__column-5 {
  width: 16%;
}
.crancy-collection__single {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 9px 95px rgba(0, 0, 0, 0.05);
}
.crancy-collection__head img {
  border-radius: 12px;
}
.crancy-collection__author {
  margin-top: 15px;
}
.crancy-collection__author {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crancy-collection__author-head,
.crancy-collection__author-head a {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy-collection__item a {
  color: #2f3032;
  font-size: 1.125rem;
  font-weight: 400;
}
.crancy-collection__author-head img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.crancy-collection__title {
  font-size: 20px;
  margin: 0;
}
.view-wallet__btn.view-trnsc a {
  font-size: 14px;
  border-bottom: 1px solid;
  line-height: 20px;
}
.crancy-newtrans__menu {
  display: flex;
  gap: 25px;
  align-items: center;
  flex-direction: row;
}
.crancy-newtrans__menu a {
  padding: 0;
  margin: 0;
  background: transparent !important;
  border: none !important;
  color: #9aa2b1;
  border-bottom: 1px solid transparent !important;
  border-radius: 0px !important;
  font-weight: 400;
}
.crancy-newtrans__menu .list-group-item {
  border-radius: none !important;
}
.crancy-newtrans__menu .list-group-item.active,
.crancy-newtrans__menu .list-group-item:hover {
  color: #0a82fd;
  border-bottom-color: #0a82fd !important;
}
.crancy-newtrans__title {
  margin: 0;
}

.crancy-newtrans__single {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e4fe;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.crancy-newtrans__single:hover {
  border-bottom-color: #0a82fd;
}
.crancy-newtrans__single:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.crancy-newtrans__info {
  display: flex;
  gap: 15px;
  align-items: center;
}
.crancy-newtrans__added {
  font-size: 16px;
  color: #2f3032;
}
.crancy-newtrans__added span {
  font-weight: 400;
  display: block;
  font-size: 14px;
  color: #9aa2b1;
}
.crancy-newtrans__icon {
  max-width: 40px;
}
.crancy-newtrans__added {
  margin: 0;
  font-weight: 400;
}
.crancy-balance__sub {
}
.crancy-newtrans__amount p {
  margin: 0;
  font-size: 16px;
}
.crancy-balance__sub .crancy-newtrans__debit {
  color: #eb5757;
}
.view-wallet__btn.top-sell a {
  color: #0a82fd;
}
.crancy-balance__wallet-box {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #2f303296;
  text-align: center;
  font-size: 26px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 15px;
}
.crancy-charts__line {
  display: flex;
  justify-content: space-between;
  color: #f0e9fe;
}

.crancy-wallet__one {
  width: 51.5%;
}
.crancy-wallet__two {
  width: 48.5%;
}

.crancy-balance__wallet-box span {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.crancy-balance__current {
  padding: 36px;
  border-radius: 15px;
}
.crancy-balance__all {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 9px 95px rgba(0, 0, 0, 0.05);
}
.crancy-sellbox__single {
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
}
.crancy-sellbox__heading-title {
  background: #f2994a;
  font-size: 20px;
  padding: 22px 30px;
  color: #fff;
  font-weight: 400;
}
.crancy-balancetop {
  display: flex;
  justify-content: space-between;
}
.crancy-transaction {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: revert;
  text-align: right;
}
.crancy-transaction__title {
  color: #fff;
  font-size: 26px;
  margin: 0;
}
.crancy-transaction__text {
  color: #cbccfe;
  font-size: 18px;
  letter-spacing: 0.025em;
  margin-top: 2px;
}
.crancy-balance__all .crancy-balance_list {
  padding: 0;
  gap: 25px;
}
.crancy-balance__all .crancy-balance_list li {
  margin: 0;
  padding: 0;
  border: none;
}

.crancy-balance__wallet-text {
  margin: 0;
  color: #cfd0fe;
  font-size: 18px;
}
.crancy-balance__wallet-amount {
  font-size: 44px;
  color: #fff;
  line-height: 52px;
  margin-top: 12px;
  margin-bottom: 0;
}

.crancy-balance__wallet-add {
  color: #fff;
  font-size: 18px;
}
.crancy-balance__wallet-add span {
  color: #51bc74;
}
.crancy-balance__sub {
  display: block;
  font-size: 14px;
  color: #9aa2b1;
  font-weight: 400;
}
.crancy-balance__sub span {
  color: #219653;
}
.crancy-progress__single {
  position: relative;
  text-align: center;
  line-height: initial;
  display: flex;
  align-items: center;
}
.crancy-balance__current,
.crancy-balance__all {
  min-height: 434px;
}

.crancy-balance__all .crancy-balance-desc {
  display: flex;
  gap: 18px;
  align-items: center;
}
.crancy-balance__all .crancy-balance__sub {
  margin: 5px 0 0;
}
.crancy-progress {
  display: flex;
  gap: 70px;
  padding-top: 10px;
}
.crancy-progress__single .number {
  position: absolute;
  top: 0;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0;
  height: 100%;
}
.circle {
  position: relative;
}
.crancy-progress__single strong {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.crancy-progress__single canvas {
  width: 72px;
}
.crancy-progress-card {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 25px;
  justify-content: space-between;
  border-radius: 8px;
}
.crancy-progress-card__title {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  color: #2f3032;
  line-height: initial;
  margin-bottom: 0;
}
.crancy-progress-card__title b {
  font-weight: 600;
}
.crancy-progress-card__history {
  font-size: 16px;
  font-weight: 400;
  color: #9aa2b1;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.crancy-progress-card__percent {
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.crancy-paymentm__icon.crancy-paymentm__icon--notify {
  background: transparent;
  width: 60px;
  height: 60px;
}
.crancy-progress-card__text {
  font-size: 14px;
}
.crancy-paymentm__text.crancy-paymentm__text--notify {
  font-size: 16px;
  color: #9aa2b1;
}
.crancy-paymentm__title.crancy-paymentm__title--notify {
  margin-bottom: 2px;
  font-size: 18px;
}
.crancy-sellbox__single .crancy-amount__statics {
  padding: 8px 28px;
  margin-bottom: 102px;
}
.crancy-sellbox__single .crancy-amount__statics__title {
  font-size: 26px;
}
.crancy-sellbox__single .crancy-amount__statics__text {
  font-size: 18px;
  color: #9aa2b1;
}
.crancy-amount__statics__text span {
  margin-left: 10px;
  font-size: 14px;
}
.crancy-amount__statics__debit {
  color: #eb5757;
}
.crancy-amount__statics__credit {
  color: #27ae60;
}

.crancy-sellbox__earning .crancy-sellbox__heading-title {
  background: #0a82fd;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-progress {
    gap: 30px;
  }
  .crancy-ptabs__verified-button {
    min-width: 35%;
  }
  .crancy__item-input {
    height: 48px;
    line-height: 48px;
  }
  .crancy-psidebar a {
    font-size: 14px;
  }
  .crancy-psidebar__icon {
    width: 30px;
  }
  .crancy-newtrans__box,
  .charts-main__invest {
    min-height: 395px;
  }
  .crancy-sellbox__heading-title {
    padding: 22px 22px;
  }
  .crancy-progress-card__history {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
  .crancy-psidebar {
    padding: 25px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-pcats__list li a {
    font-size: 16px;
  }
  .crancy-pcats__list {
    gap: 25px;
  }
  .crancy-pcats__list li a::before {
    bottom: -24px;
  }
  .crancy-psidebar a {
    flex-direction: column;
  }
  .crancy-psidebar__list {
    gap: 5px;
  }
  .crancy-psidebar__icon {
    width: auto;
  }
  .crancy-psidebar__title {
    font-size: 15px;
    text-align: center;
  }
  .crancy-ptabs__verified-button {
    min-width: 35%;
  }
  .crancy-btn.crancy-ptabs__verified-btn {
    font-size: 14px;
  }
  .crancy-wallet__one,
  .crancy-wallet__two {
    width: 100%;
  }
  .crancy-psidebar {
    padding: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .crancy-progress {
    display: flex;
    gap: 25px;
    padding-top: 10px;
  }
  .crancy-header__form-profile {
    width: 100%;
  }
  .crancy-pcats {
    margin-top: 30px;
  }
  .crancy-pcats__list li a {
    font-size: 16px;
  }
  .crancy-userprofile__info-text {
    padding-right: 0;
  }
  .crancy-psidebar__title {
    display: none;
  }
  .crancy-ptabs__verified {
    flex-direction: column;
  }
  .crancy-ptabs__sidebar {
    margin-top: 30px;
  }
  .crancy-paymentm__icon {
  }
  .crancy-paymentm__single {
    flex-direction: column;
  }
  .crancy-paymentm__manage-btn {
    margin-top: 20px;
  }
  .crancy-paymentm__icon {
    min-width: 80px;
    min-height: 80px;
    line-height: 80px;
    width: 80px;
    height: 80px;
  }
  .crancy-paymentm__icon img {
    max-width: 35px;
  }
  .crancy-paymentm__single {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .crancy__item-form--group .crancy__item-form--group {
    margin-top: 20px;
  }
  .crancy-personals__history {
    width: 100%;
    overflow: scroll;
  }
  .crancy-personals__history #crancy-table__main {
    width: 600px;
  }
  .crancy-password__img {
    margin-top: 30px;
  }
  .crancy-psidebar__list {
    gap: 10px;
  }
  .crancy-userprofile__meta {
    margin-right: 12px;
    padding-right: 12px;
    font-size: 16px;
  }
  .crancy-psidebar__heading {
    font-size: 14px;
    display: none;
  }
  .crancy-ptabs__separate {
    flex-direction: column;
  }
  .crancy-paymentm__name {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
  .crancy-wallet__one,
  .crancy-wallet__two {
    width: 100%;
  }
  .crancy__item-label {
    font-size: 15px;
  }
  .crancy-psidebar {
    padding: 10px 0;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-psidebar a {
    padding: 5px;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .crancy-psidebar__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.crancy-table__status__group {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
}
.crancy-table__trash a {
  color: #9aa2b1;
}
.crancy-table__trash a:hover {
  color: #eb5757;
}

.crancy-password__img {
  max-width: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.crancy-accordion__single .accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(83, 86, 251, 0.16);
}
.accordion-button.crancy-accordion__heading {
  background: transparent;
  font-size: 18px;
  font-weight: 700;
  padding: 22px 20px 22px 0;
  color: #2f3032;
}
.crancy-accordion__single .accordion-button:not(.collapsed) {
  color: #0a82fd;
  background-color: transparent;
  box-shadow: none;
}
.accordion-button.crancy-accordion__heading {
  background: transparent;
  font-size: 18px;
  font-weight: 500;
  padding: 25px 0;
  color: #2f3032;
  height: initial;
}
.crancy-accordion__single .accordion-button:not(.collapsed) {
  color: #0a82fd;
  background-color: transparent;
  box-shadow: none;
}
.crancy-faqs {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
}
.crancy-faq__area {
  padding: 70px 0 100px;
}
#crancy-accordion {
  position: relative;
}
.accordion-body.crancy-accordion__body {
  font-size: 16px;
  color: #9aa2b1;
  padding: 0 0 15px;
}
.accordion-flush .accordion-item:last-child {
  border: none;
}
.accordion-item.crancy-accordion__single {
  border-bottom: 1px solid #e4f2ff;
  border-top: none !important;
}
blockquote {
  background: #f2c94c;
  padding: 25px;
  color: #2f3032;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 30px;
  line-height: 30px;
}
.crancy-ptabs__page p {
  margin-bottom: 20px;
  line-height: 28px;
}
.crancy-ptabs__page p:last-child {
  margin: 0;
}
.crancy-ptabs__sauthor {
  position: relative;
}
.crancy__item-group {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid #e4f2ff;
}
.crancy-ptabs__sedit {
  position: absolute;
  bottom: 12px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  right: 12px;
  background: #0a82fd;
  cursor: pointer;
  margin: 0;
}
.crancy-ptabs__sedit:hover {
  background: #0a82fd;
  color: #fff;
}
.crancy-ptabs__stitle {
  font-size: 24px;
  margin: 0 0 10px;
  font-weight: 500;
}
.crancy-ptabs__sheading {
  margin-bottom: 15px;
}
.crancy-ptabs__stext {
  line-height: 28px;
}
.crancy-ptabs__stext {
  line-height: 1.5rem;
}
.crancy-ptabs__stitle i {
  color: #9aa2b1;
}
.crancy-ptabs__scover .crancy-ptabs__pthumb {
  max-width: 100%;
  display: inline-flex;
}
.crancy-ptabs__ssingle.crancy-ptabs__scover .crancy-ptabs__sedit {
  bottom: 5px;
  right: 5px;
  margin: 0;
  background: #2f3032;
}
.crancy-ptabs__ssingle.crancy-ptabs__scover .crancy-ptabs__sedit:hover {
  background-color: #0a82fd;
}
.crancy-personals__history .crancy-table__column-6 {
  width: 1% !important;
}
.crancy-personals__history .crancy-table__body tr td {
  padding: 15px 0;
}
.crancy-personals__history .crancy-table__product-desc {
  color: #2f3032;
  font-size: 16px;
}
.crancy-personals__history .crancy-table__product-desc.crancy-normal-text {
  color: #9aa2b1;
}
.crancy-personals__history .crancy-table__column-5 {
  width: 10% !important;
}

.crancy-personals__history .crancy-table__column-1 {
  width: 10%;
}
.crancy-personals__history .crancy-table__body tr td {
  padding: 13px 0;
}
.crancy__item-button--group--profile {
  justify-content: center;
}
.crancy__item-button--group--profile button {
  font-size: 18px;
}
.crancy__item-button--group--profile .crancy__item-button--cancel {
  font-weight: 400;
}
.crancy-ptabs__form-update {
  width: 368px !important;
  min-width: 368px;
  padding: 25px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e4f2ff;
}
.crancy-table__main--activity .crancy-table__body tr td,
.crancy-table__main--activity .crancy-table__body tr th {
  width: 20% !important;
}

.crancy-ptabs__ssingle {
  margin-bottom: 30px;
}
.crancy-ptabs__ssingle:last-child {
  margin: 0;
}
.crancy-ptabs__sedit.crancy-ptabs__cedit {
  background: #2f3032;
  bottom: -16px;
  right: 10px;
}
.crancy-ptabs__sauthor {
  display: flex;
  flex-direction: column;
}
.crancy-ptabs__sauthor-img {
}
.crancy-ptabs__pthumb {
  max-width: 178px;
  position: relative;
}
.crancy-ptabs__pthumb input {
  display: none;
}
.crancy-userprofile__meta a {
  color: #82829a;
  font-weight: 400;
}
.followers-list__single {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.followers-list__first {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.followers-list__title {
  margin: 0;
}
.followers-list__badge {
  position: absolute;
  top: -5px;
  right: 0;
  max-width: 18px;
}
.followers-list__follower {
  position: relative;
}
.followers-list__follower {
  width: 60px;
}
.followers-list__text {
  margin: 0;
}
.followers-list__button a {
  padding: 13px 30px;
}
.followers-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.followers-list__button a {
  width: 115px;
  text-align: center;
  padding: 13px 10px;
}
.crancy-btn.crancy-btn__bordered.crancy-btn__followed:before {
  visibility: hidden;
}
.crancy-btn.crancy-btn__bordered.crancy-btn__followed::after {
  background: linear-gradient(
    134.38deg,
    #0a82fd 0%,
    #c342f9 43.55%,
    #0a82fd 104.51%
  );
  opacity: 0.17;
  border-radius: 61px !important;
}
.crancy-followers__modal .modal-content {
  width: 400px !important;
  min-width: 400px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .crancy-followers__modal .modal-content,
  .modal-content,
  .crancy-close__modal-close .modal-content {
    width: 100% !important;
    max-width: 100% !important;
    justify-content: inherit;
    margin-left: initial;
  }
  .crancy-project-preview__heading {
    font-size: 20px;
  }
  .crancy-faqs {
    padding: 25px;
  }
}
/*======================================
	End crancy Profiles CSS
========================================*/

/*======================================
	Pages CSS
========================================*/
.crancy-pricing {
  background: #ffffff;
}
.crancy-pricing {
  padding: 90px 30px 30px;
}
.crancy-psingle {
  background: #f8fbff;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 30px;
}
.crancy-psingle__list {
  padding: 25px;
}
.crancy-psingle__list li {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.crancy-psingle__list li:last-child {
  margin: 0;
}
.crancy-psingle__button {
  padding: 0 25px 25px;
}
.crancy-psingle__head {
  padding: 18px 25px;
}
.crancy-psingle__body {
  border: 1px solid #e4f2ff;
}
.crancy-psingle__title {
  font-size: 54px;
  color: #fff;
  margin: 0;
  line-height: initial;
  font-weight: 700;
}
.crancy-psingle__title span {
  font-size: 18px;
  font-weight: 500;
}
.crancy-psingle__title span.crancy-psingle__currency {
  top: -22px;
  position: relative;
  font-weight: 700;
  font-size: 20px;
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-psingle__title {
    font-size: 36px;
  }
  .crancy-psingle__list li {
    margin-bottom: 15px;
  }
  .crancy-funfact__title {
    font-size: 35px;
  }
}

/*======================================
	End Pages CSS
========================================*/

/*======================================
	Forms CSS
========================================*/
.crancy-wc__full {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.crancy-wc__logo {
  padding-top: 40px;
  padding-left: 40px;
}
.crancy-wc__logo img {
  max-width: 235px;
}
.crancy-wc__form--middle {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  max-width: 1160px;
}
.crancy-wc__banner {
  background: #0a82fd;
  min-width: 50%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}
.crancy-wc-bg {
  background-color: #e8e5fe;
}
.crancy-wc__inner {
  padding: 66px;
}
.crancy-wc__middle {
  display: flex;
  justify-content: center;
}
.crancy-wc__middle img {
  max-width: 655px;
}
.crancy-wc__title {
  font-size: 60px;
  line-height: 74px;
  margin: 0px 0 0;
  color: #fff;
}
.crancy-wc__inner {
  padding: 66px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* Welcome Form */
.crancy-wc__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 50px 0;
}
.crancy-wc__heading {
  overflow: hidden;
  margin-bottom: 0;
}
.crancy-wc__form-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 70px 65px;
  min-width: 50% !important;
}
.crancy-wc__form-title {
  font-size: 32px;
  width: auto;
  background: transparent;
  margin-bottom: 10px;
}
.crancy-wc__checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.ntfmax-wc__btn.ntfmax-wc__btn--login span {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #3b9bfd;
  position: absolute;
  right: 15px;
  font-size: 14px;
}
.ntfmax-wc__btn.ntfmax-wc__btn--login {
  width: 100%;
  justify-content: center;
  gap: 78px;
  padding: 0 15px;
  position: relative;
}
.crancy-wc__button.crancy-wc__button--center {
  align-items: center;
  padding: 0 70px;
}
.form-group.form-group--big {
  margin-top: 80px;
}
.form-group.form-group--big {
  margin-top: 75px !important;
}

.homec-form-checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
}
.homec-form-checkbox label {
  cursor: pointer;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px !important;
  height: 20px !important;
  background: #eceaff;
  border-radius: 0%;
  outline: none;
  cursor: pointer;
  position: relative;
  border-radius: 100%;
  padding: 0 !important;
  border-radius: 100% !important;
  background: transparent !important;
  border: 2px solid #cbd5e0 !important;
}
input[type="checkbox"]:checked {
  background: #0a82fd !important;
}
input[type="checkbox"]:checked::before {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #fff;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 10px;
}
.form-group.crancy-wp__form--group {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  background: #f2f6ff;
  border-radius: 12px;
  padding: 15px 20px;
  width: 100%;
}
.form-group.crancy-wp__form--group label {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.crancy-wc__select--tab {
  background: #e8edff;
  display: inline-flex;
  padding: 6px 10px;
  border-radius: 8px;
  gap: 5px;
  border-bottom: 0px;
}
.crancy-wc__select--tab a {
  border-radius: 0px !important;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px !important;
}
.crancy-wc__select--tab a.active,
.crancy-wc__select--tab a:hover {
  background: #0a82fd;
  color: #fff;
}
.form-group.crancy-wp__form--group label span {
  color: #9aa2b1;
}
.crancy-wc__form--inner--heading {
  color: #2f3032;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.crancy-wc__form-main--select {
  max-width: 70%;
}

/* crancy Form */
.crancy-wc__form-main .form-group {
  margin-top: 20px;
  position: relative;
}
.crancy-wc__form-main input {
  width: 100%;
  height: 50px;
  padding: 5px 50px 5px 20px;
  background: #fafafa;
  color: rgba(57, 62, 70, 0.2);
  /* border: 1px solid #E4F2FF; */
  border-radius: 4px;
  border: 1px solid transparent;
  color: #191b23 !important;
}
.crancy-wc__form-main .form-group input:hover {
  border-color: #0a82fd;
}

.crancy-wc__form-main .form-group:hover .crancy-wc__icon {
  color: #0a82fd;
}
.crancy-wc__form-main .form-group__input {
  position: relative;
}
.crancy-wc__form-main .crancy-wc__icon {
  position: absolute;
  right: 29px;
  top: 50%;
  color: #85878b;
  transition: all 0.3s ease;
  cursor: pointer;
  line-height: initial;
  margin-top: -12px;
}
.crancy-wc__form-label {
  font-size: 16px;
}
.crancy-wc__check-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crancy-wc__check-inline label {
  margin: 0;
}
.crancy-wc__check-inline label a {
  font-weight: 400;
}
.crancy-wc__form-new {
  padding-top: 25px;
  padding-bottom: 25px;
}
.form-group.crancy-wc__new-bottom {
  margin-top: 35px;
}
.forgot-pass {
  font-size: 16px;
  font-weight: 400;
}
.crancy-wc__button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.ntfmax-wc__btn {
  color: #fff;
  border: none;
  font-size: 18px;
  background: #0a82fd;
  border-radius: 10px;
  min-height: 55px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}
.crancy-wc__button .ntfmax-wc__btn.ntfmax-wc__btn-google {
  color: #858d98;
  font-weight: 400;
}
.crancy-wc__button .ntfmax-wc__btn:hover,
.crancy-wc__button .ntfmax-wc__btn.ntfmax-wc__btn-google:hover {
  background: #0a82fd;
}
.crancy-wc__bottom a {
  color: #2f3032;
  font-weight: 400;
}
.crancy-wc__bottom a:hover {
  color: #0a82fd;
}
.ntfmax-wc__btn.ntfmax-wc__btn--two {
  background: transparent;
  color: #2f3032;
  border: 1px solid #eeeff2;
  font-weight: 400;
}
.ntfmax-wc__btn.ntfmax-wc__btn--two:hover {
  border-color: #0a82fd;
}
.crancy-wc__button img {
  max-width: 18px;
}
.crancy-wc__button button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.crancy-wc__footer--copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #0a82fd;
  font-size: 16px;
  margin-top: 15px;
}
.crancy-wc__footer--copyright a {
  font-weight: 400;
}
.crancy-wc__toggle {
  position: absolute;
  right: 15px;
  top: 0;
  color: #9aa2b1;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-wc__form-login--label {
  text-align: center;
  position: relative;
  margin-top: 20px;
}
.crancy-wc__form-login--label:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #eeeff2;
}
.crancy-wc__form-login--label span {
  background: #fff;
  color: #9aa2b1;
  z-index: 3333;
  position: relative;
  padding: 0 25px;
  font-size: 16px;
}
.ntfmax-wc__btn-icon {
  width: 22px;
  height: 22px;
  background: transparent;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.crancy-wc__button .ntfmax-wc__btn.ntfmax-wc__btn-google:hover {
  color: #fff;
}
.crancy-wc__button
  .ntfmax-wc__btn.ntfmax-wc__btn-google:hover
  .ntfmax-wc__btn-icon {
  background: #fff;
}
.crancy-wc__text {
  font-size: 16px;
  color: #9aa2b1;
  margin: 0;
  font-weight: 500;
}
.crancy-wc__text a {
  color: #0a82fd;
  text-decoration: underline;
  font-weight: 500;
}
.crancy-wc__button--group {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.crancy-wc__footer--language:after {
  border-color: #fff;
  width: 7px;
  height: 7px;
  margin-top: -5px;
}
.crancy-wc__footer--list {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0;
  flex-wrap: wrap;
}
.crancy-wc__footer--list li a {
  color: #2f3032;
  font-weight: 400;
  font-size: 14px;
}
.crancy-wc__footer--text {
  text-align: center;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
}
.crancy-wc__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 40px;
}
.crancy-wc__footer--language,
.crancy-wc__footer--languages .nice-select {
  background: transparent;
  border: none;
  color: #2f3032;
  background: transparent !important;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  min-height: initial !important;
  height: initial !important;
  margin: 0;
}
.crancy-wc__footer--languages .select2-selection.select2-selection--single {
  background: transparent;
  border: none !important;
  padding: 0;
  margin: 0;
  color: #fff;
  gap: 0 !important;
  justify-content: initial;
}
.crancy-wc__footer--languages
  .select2-selection.select2-selection--single
  span {
  color: #fff !important;
  padding: 0;
  color: #fff !important;
  font-weight: 500;
  color: #fff;
}
.crancy-wc__footer--languages
  .select2-selection.select2-selection--single
  span
  b {
  border-color: #fff !important;
  width: 8px;
  height: 8px;
}
.crancy-wc__footer--language .list {
  width: 130px !important;
  bottom: 25px;
  top: initial;
}
.nice-select.crancy-wc__footer--language::after {
  right: -15px;
  border-right-color: #2f3032;
  border-bottom-color: #2f3032;
  width: 8px;
  height: 8px;
}
/* Verify Form */
.crancy-wc__form-verify {
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.crancy-wc__form .crancy-wc__form-verify input {
  padding: 0 !important;
  text-align: center;
  width: 64px;
  height: 64px;
  border: 1px solid #e4f2ff;
  color: #0a82fd !important;
  font-weight: 500;
  font-size: 24px;
  background: #e7f3ff;
  border-radius: 8px;
}
.crancy-wc__form-verify input::-moz-placeholder {
  color: #0a82fd !important;
  opacity: 1 !important;
}
.crancy-wc__form-verify input::-webkit-input-placeholder {
  color: #0a82fd !important;
  opacity: 1 !important;
}
.crancy-wc__form-verify input:-ms-input-placeholder {
  color: #0a82fd !important;
  opacity: 1 !important;
}
.crancy-wc__form-verify input::-ms-input-placeholder {
  color: #0a82fd !important;
  opacity: 1 !important;
}
.crancy-wc__form-inner.bg-mask-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.crancy-wc__button-forget {
  margin-top: 25px;
}

.crancy-wc__form-edit {
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 15px;
}
.crancy-wc__form-edit p {
  font-weight: 500;
  font-size: 16px;
}
.crancy-wc__form-main--verify {
  max-width: 72%;
}
/* Update Password */
.crancy-wc__middle-update {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
}
.crancy-wc__update-contnet-title {
  font-size: 36px;
  margin-bottom: 0;
}
.crancy-wc__update-contnet-text {
  font-size: 18px;
  color: #9aa2b1;
  margin: 0;
  line-height: 32px;
  font-weight: 400;
}
.crancy-wc__update img {
  max-width: 154px;
}
.form-mg-top15 {
  margin-top: 15px !important;
}
.form-mg-top25 {
  margin-top: 25px !important;
}
.form-mg-top30 {
  margin-top: 30px !important;
}
.crancy-wc__bottom--reset {
  color: #2f3032;
  font-weight: 400;
  border-bottom: 1px solid;
  line-height: initial;
}
.crancy-wc__ficon {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 25px;
  padding-right: 10px;
  border-right: 1px solid #d5dae5;
  line-height: initial;
}
.crancy-wc__form-main--password input {
  padding-left: 70px;
  height: 65px;
  background: transparent;
  border: 1px solid #bfcdff;
  border-radius: 12px;
}

.crancy-wc__slist {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy-wc__slist img {
  max-width: 20px;
}
.crancy-wc__slist a {
  width: 20px;
  background: rgba(10, 130, 253, 0.1);
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
}
.crancy-wc__slist--info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

@media only screen and (min-width: 0px) and (max-width: 1550px) {
  .crancy-wc__form,
  .crancy-wc__full {
    height: initial;
    min-height: initial;
  }
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-wc__heading {
    margin: 0;
  }
  .crancy-wc__footer {
    margin-top: 20px;
  }
  .form-mg-top30 {
    margin-top: 20px !important;
  }
  .crancy-wc__form-title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 1200px) {
  .crancy-wc__form {
    min-height: 100vh;
    justify-content: start;
  }
}
@media only screen and (min-width: 0px) and (max-width: 991px) {
  .crancy-wc__banner {
    display: none !important;
  }
  .crancy-wc__form-main--verify {
    max-width: 100%;
  }
  .crancy-wc__form--middle {
    min-width: 80%;
    max-width: 100%;
  }
  .crancy-wc__form-inner {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 40px;
  }
  .crancy-wc__button.crancy-wc__button--center {
    align-items: center;
    padding: 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .crancy-wc__form {
    padding: 50px 0 !important;
    height: initial;
  }
  .crancy-wc__form-title {
    font-size: 20px;
  }
  .crancy-wc__text {
    font-size: 16px;
  }
  .crancy-wc__check-inline label {
    font-size: 15px;
  }
  .forgot-pass {
    font-size: 15px;
  }
  .crancy-wc__form-verify {
    gap: 10px;
  }
  .crancy-wc__update-contnet-title {
    font-size: 22px;
  }
  .crancy-wc__update img {
    max-width: 104px;
  }
  .crancy-wc__update-contnet-text {
    font-size: 15px;
  }
  .crancy-wc__button .ntfmax-wc__btn {
    font-size: 16px;
  }
  .crancy-wc__footer {
    flex-direction: column;
  }
  .crancy-wc__footer--languages {
    display: none;
  }
  .crancy-wc__footer {
    margin-top: 20px;
  }
  .crancy-wc__form-main--select {
    max-width: 100%;
  }
  .crancy-wc__form-main--verify {
    max-width: 100%;
  }
  .form-group.form-group--big {
    margin-top: 40px !important;
  }
  .crancy-wc__form .crancy-wc__form-verify input {
    width: 46px;
    height: 46px;
  }
}

/*======================================
	End Forms CSS
========================================*/

/*======================================
	Utilities CSS
========================================*/
.crancy-forms {
  padding: 100px;
  background: #fefaed;
}
.crancy-main-form__right button {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
}
.crancy-main-form__right {
  display: flex;
  justify-content: right;
  gap: 20px;
  align-items: center;
}
.crancy-main-form__top {
  background: #e4f2ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 25px;
  border-radius: 12px 12px 0 0;
}
.crancy-main-form__title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.crancy-main-form__inner {
  background: #fff;
  padding: 25px;
}
.crancy-main-form__group .nice-select,
.crancy-main-form__group input,
.crancy-main-form__group textarea,
.crancy-main-form__group select {
  width: 100%;
  background-color: #fafafa !important;
  height: 58px;
  border: 1px solid #e4f2ff !important;
  padding: 0 18px !important;
  color: #9aa2b1;
  font-size: 18px;
  border-radius: 4px !important;
}
.crancy-main-form__inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.crancy-main-form__group textarea {
  height: 200px;
  padding: 20px 18px !important;
}
.crancy-main-form__button button {
  height: 50px;
  background: #0a82fd;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  padding: 10px 32px;
  font-weight: 400;
  width: initial;
}
.crancy-main-form__single input {
  display: none;
}
.crancy-main-form__single svg {
  fill: #0a82fd;
}
.crancy-main-form__single .crancy-main-form__icon {
  width: 52px;
  height: 48px;
  background: #e4f2ff;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.crancy-main-form__single .crancy-main-form__icon:hover {
  background: #0a82fd;
}
.crancy-main-form__single .crancy-main-form__icon:hover svg {
  fill: #fff;
}
.crancy-main-form__options {
  display: flex;
  /*! align-items: center;
    */
  gap: 10px;
}
.crancy-main-form__button {
  display: flex;
  justify-content: space-between;
}
.crancy-cta__content--img {
  max-width: 380px;
}
.crancy-main-form__group select {
  background-image: url("../img/arrow-down-svg.svg");
  background-size: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 1550px) {
  .crancy-forms {
    padding: 50px;
  }
  .crancy-main-form__single .crancy-main-form__icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }
  .crancy-main-form__single .crancy-main-form__icon {
    height: 44px;
    line-height: 44px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  .crancy-cta__content--img {
    display: none;
  }
  .crancy-forms {
    padding: 50px 30px;
  }
  .crancy-main-form__group input,
  .crancy-main-form__group textarea,
  .crancy-main-form__group select {
    font-size: 16px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .crancy-faq__img,
  .crancy-support__img {
    display: none;
  }
  .crancy-main-form__button {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.crancy-cta__title {
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: 700;
}
.crancy-header__form.crancy-cta__search form {
  width: 495px;
  background: #fff;
  padding: 0;
}
.crancy-header__form.crancy-cta__search form input {
  padding: 0 85px 0 20px;
}
.crancy-cta__content {
  max-width: 550px;
}
.crancy-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e4f2ff;
  padding: 30px;
  border-radius: 8px;
}
.crancy-header__form.crancy-cta__search {
  margin-top: 30px;
}
.crancy-header__form.crancy-cta__search form input {
  background: #fff;
  height: 60px;
}
.crancy-header__form.crancy-cta__search form .search-btn {
  top: 0;
  min-width: 80px;
  height: 100%;
  background: #0a82fd;
  position: absolute;
  right: 0;
  left: initial;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .crancy-cta__title {
    font-size: 18px;
  }
  .crancy-cta {
    padding: 20px;
  }
  .accordion-button.crancy-accordion__heading {
    font-size: 16px !important;
  }
}
.crancy-section__title {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 5px;
}
.crancy-section-title {
  text-align: center;
}
.crancy-usernavs {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.crancy-usernavs a.crancy-btn {
  padding: 10px 21px;
}
.crancy-usernavs .crancy-btn.crancy-btn__share {
  width: 46px;
  height: 46px;
  line-height: 46px;
  padding: 0;
}
.crancy-userprofile__header {
  position: relative;
}
.crancy-userprofile__header img {
  border-radius: 12px;
  width: 100%;
  min-height: 100px;
  object-fit: cover;
}
.crancy-userprofile__right-v2 {
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -26px;
}
/* History Widget */
.crancy-history {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px 20px;
}
.crancy-history__content {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.crancy-history__number {
  margin: 0;
  color: #2f3032;
  font-size: 26px;
  display: flex;
  flex-direction: column;
  line-height: initial;
}
.crancy-history__number span {
  font-size: 16px;
  font-weight: 400;
  color: #9aa2b1;
  padding-top: 5px;
}
.crancy-history__text {
  margin: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: end;
}
.crancy-history__amount {
  margin: 0;
  font-size: 18px;
  color: #27ae60;
}
.crancy-history__icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
}
.crancy-history__icon img {
  max-width: 30px;
}
.crancy-history__amount.crancy-history__amount-debit {
  color: #eb5757;
}
.crancy-history__icon.crancy-history__icon-two {
  background: #feecff;
}
.crancy-history__icon.crancy-history__icon-three {
  background: #eaf7f0;
}
.crancy-history__icon.crancy-history__icon-four {
  background: #fdefef;
}
.crancy-history.crancy-history--ticket .crancy-history__icon {
  border-radius: 4px;
}
.crancy-history.crancy-history--ticket .crancy-history__text--one {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy-history.crancy-history--ticket .crancy-history__content {
  margin-bottom: 15px;
}
.crancy-history.crancy-history--ticket .crancy-history__text {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
}
.crancy-history__text--percent {
  font-weight: 500;
}
.crancy-history.crancy-history--ticket .crancy-history__amount {
  display: flex;
  align-items: center;
  gap: 5px;
}
.crancy-history.crancy-history--ticket .crancy-progressbar__single {
  background-color: #c6e0fb;
}
.crancy-history.crancy-history--ticket span {
  padding-top: 2px;
}
.crancy-table__main-history .crancy-table__column-1 {
  width: 8%;
}
.crancy-table__main-history .crancy-table__column-2 {
  width: 26%;
}
.crancy-topsell__country .crancy-newtrans__single {
  border: none;
  padding: 0;
  margin-bottom: 32px;
}
.crancy-topsell__country h4 {
  font-size: 18px;
}
.crancy-topsell__country .crancy-newtrans__single:last-child {
  margin: 0;
}
.crancy__item-title.crancy__item-title--psingle {
  font-size: 18px;
  letter-spacing: 0.025em;
}
.crancy__item-text--single {
  letter-spacing: 0.025em;
}
.crancy-wc__form-main--profile .crancy-wc__icon.lock {
  right: initial;
  left: 29px;
}
.crancy-wc__form-main--profile .crancy-wc__icon,
.crancy-wc__form-main--profile .crancy-wc__icon svg {
  font-size: 20px;
  line-height: initial;
}
.crancy-wc__form-main--profile .crancy-wc__icon svg {
  margin-top: -5px;
}
@media only screen and (min-width: 1278px) and (max-width: 1500px) {
  .crancy-history__main--home .crancy-history__text {
    font-size: 14px;
  }
  .crancy-history.crancy-history--ticket .crancy-history__main {
    padding: 15px;
  }
  .crancy-history.crancy-history--ticket .crancy-history__text p {
    font-size: 12px;
  }
  .crancy-teams__title,
  .crancy-section__title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  #myChart_bids_month,
  #myChart_earning_month {
    max-height: 200px !important;
  }
  .crancy-section__title {
    font-size: 24px;
  }
  .crancy-section__text {
    font-size: 15px;
  }
}
.crancy-accordion.crancy-according__showcase {
  background: #fff;
  padding: 5px 20px;
  border-radius: 15px;
}
@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .crancy-table__product-history .crancy-table__column-2 {
    width: 15%;
  }
  .crancy-table__product-history .crancy-table__column-3 {
    width: 18%;
  }
  .crancy-table__product-history .crancy-table__product-title {
    font-size: 18px;
  }
  .crancy-table__text {
    font-size: 14px;
  }
  .crancy-hidden-rp {
    display: none;
  }
  .crancy__file-upload {
    width: 100%;
  }
  .col-lg-5.col-12.crancy-pcolumn__one {
    width: 100%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .dashboard-banner__group-title {
    font-size: 18px;
  }
  .dashboard-banner__group {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .dashboard-banner .dashboard-banner__bid {
    padding: 24px 10px;
  }
  .welcome-cta {
    padding: 15px;
  }
  .charts-main__heading .crancy-chart__dropdown {
    margin-top: 0;
  }
  .charts-main__heading {
    gap: 10px;
  }
  .dashboard-banner__title {
    font-size: 20px;
  }
  .dashboard-banner__button {
    margin-top: 20px;
  }
  .all-notificaiton__inner {
    gap: 15px;
  }
  .all-notificaiton__inner .list-group-item {
    font-size: 15px;
  }
  .crancy-currency__shop-detail {
    margin-top: 25px;
    padding-top: 0;
  }
  .crancy-trendmeta__img.crancy-trendmeta__img-detail {
    max-width: 60px;
  }
  .crancy-trendmeta__content-detail .crancy-trendmeta__title {
    font-size: 16px;
  }
  .crancy-trendmeta__content-detail span {
    font-size: 14px;
  }
  .crancy-trendmeta__main_detail .crancy-trendmeta__author {
    gap: 5px;
  }
  .crancy-ptabs__form-update {
    width: 100% !important;
    min-width: 100%;
    padding: 0 30px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 991px) {
  .crancy-sixth-one,
  .crancy-sixth-two {
    width: 100%;
  }
  .charts-home-one {
    min-height: initial;
  }
  .dashboard-banner__heart {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-smenu.crancy-close .menu-bar li a {
    padding: 8px 0px;
  }
  .crancy-close__modal-close {
    min-width: initial;
  }
}
@media only screen and (max-width: 1278px) {
  .trending-action__single .crancy-trendmeta {
    margin-top: 0px;
  }
}
.crancy-sellbox__single {
  min-height: 434px;
}
.crancy-sellbox__single .crancy-sidebar__cside-one {
  height: 175px;
}
#chart__sell,
#chart__visitor {
  min-height: 265px;
  max-height: 300px;
}
.charts-main.charts-main__invest {
  min-height: 351px;
}
#chart__investment {
  max-height: 276px;
  min-height: 276px;
}
@media only screen and (min-width: 1020px) and (max-width: 1278px) {
  .crancy-sellbox__single .crancy-amount__statics {
    margin-bottom: 74px;
  }
  .crancy-sellbox__heading-title {
    font-size: 18px;
    padding: 22px 20px;
  }
}
@media only screen and (min-width: 1278px) and (max-width: 1500px) {
  .crancy-sellbox__single .crancy-amount__statics {
    margin-bottom: 74px;
  }
}
@media only screen and (max-width: 1020px) {
  .crancy-sellbox__single {
    min-height: initial;
  }
  .crancy-sellbox__single .crancy-amount__statics {
    margin-bottom: 40px;
  }
  .trending-action__single.marketplace-details .crancy-flex__center {
    --bs-gutter-y: 30px;
  }
}
@media only screen and (max-width: 767px) {
  #chart__sell,
  #chart__visitor,
  #chart__investment {
    min-height: 240px;
    max-height: 240px;
  }
}
.crancy-uwork__title {
  font-size: 16px;
  margin: 0;
}
.crancy-uwork__list {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.crancy-uwork__list li {
  display: flex;
  gap: 10px;
}
.crancy-uwork__icon {
  min-width: 56px;
  min-height: 56px;
  line-height: 56px;
  display: flex;
  justify-content: center;
  align-content: baseline;
  align-items: center;
  border-radius: 100%;
  width: 56px;
  height: 56px;
}
.crancy-uwork__content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.crancy-uwork__office {
  color: #2f3032;
}
.crancy-uwork__office span {
  color: #9aa2b1;
}
.crancy-uwork__content p {
  font-size: 14px;
}
.crancy-uactivity__meta {
  font-size: 14px;
}
.crancy-uactivity__all a {
  color: #2f3032;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
}
.crancy-uactivity__list li {
  position: relative;
}
.crancy-uactivity__list li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: -5.5px;
  top: 5px;
  background: #fff;
  border: 2px solid #0a82fd;
}
/* My Chart Progress */
.myChart-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.myChart-progress__number {
  font-size: 30px;
  margin: 0;
}
.myChart-progress__text {
  color: #9aa2b1;
  font-weight: 400;
}
.crancy-task__chart {
  position: relative;
}
.crancy-task__list {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.crancy-task__list li {
  position: relative;
  padding-left: 28px;
  align-items: center;
  display: flex;
}
.crancy-task-point {
  width: 16px;
  height: 16px;
  background: #e4f2ff;
  content: "";
  position: absolute;
  border-radius: 100%;
  left: 0;
}
.crancy-task__list li p span {
  color: #2f3032;
}
/* Gallery */
.crancy-page-inner {
  background: #fff;
  padding: 30px;
  border: 1px solid #e4f2ff;
  border-radius: 12px;
}
.crancy-single__gallery--thumb::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #0a82fd;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.crancy-single__gallery:hover .crancy-single__gallery--thumb::before {
  opacity: 0.84;
  visibility: visible;
}
.crancy-single__gallery--thumb {
  position: relative;
}
.crancy-single__gallery--thumb img {
  width: 100%;
}
.crancy-single__gallery {
  border-radius: 4px;
  overflow: hidden;
  margin-top: 25px;
}
.crancy-img__preview {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  width: 72px;
  height: 72px;
  background: #f2c94c;
  border-radius: 100%;
  margin-left: -36px;
  top: 50%;
  margin-top: -36px;
  opacity: 0;
  visibility: hidden;
}
.crancy-single__gallery:hover .crancy-img__preview {
  opacity: 0.84;
  visibility: visible;
}
/* Crancy Sidebar */
.crancy-sidebarmenu {
  z-index: 999999;
  background: #ffffff;
  box-shadow: 19px 36px 127px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none;
  width: 425px;
  transition: all 0.3s ease;
  position: fixed;
  right: 0;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  height: 100%;
}
.crancy-sidebarmenu.active {
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
}
.crancy-sidebarmenu__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 120px;
  scrollbar-width: none;
  overflow: scroll;
}
.crancy-sidebarmenu__header {
  border-bottom: 1px solid #e4f2ff;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  justify-content: space-between;
}
.crancy-sidebarmenu__title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
}
.crancy-sidebarmenu__close {
  width: 26px;
  height: 26px;
  background: #eb5757;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}
.crancy-pcats__list.rancy-sidebarmenu__menu {
  display: flex;
  gap: 10px;
}
.crancy-pcats__list.crancy-sidebarmenu__menu {
  gap: 10px;
}
.crancy-pcats__list.crancy-sidebarmenu__menu a {
  padding: 10px 14px;
  background: #e7f3ff !important;
}
.crancy-pcats__list.crancy-sidebarmenu__menu a.active,
.crancy-pcats__list.crancy-sidebarmenu__menu a:hover {
  background: #0a82fd !important;
}
.crancy-sidebarmenu__tmenu {
  padding: 25px 30px;
}
.crancy-sidebarmenu__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e4f2ff;
}
.crancy-sidebarmenu__settings {
  padding: 0 30px;
}
.crancy-sidebarmenu__main {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy-sidebarmenu__option--icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #e4f2ff;
  text-align: center;
  border-radius: 100%;
}
.crancy-sidebarmenu__option--icon svg {
  fill: #2f3032;
}
.crancy-sidebarmenu__option--title {
  margin: 0;
  font-size: 16px;
}
.crancy-sidebarmenu__option--text {
  font-size: 14px;
}
.crancy-sidebarmenu__style--menus {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 30px;
}
.crancy-sidebarmenu__style--menus img {
  border: 1px solid #e4f2ff;
  border-radius: 4px;
}
.crancy-sidebarmenu__style--menus li:hover img {
  border-color: #0a82fd;
}
.crancy-sidebarmenu__style--title {
  margin-bottom: 15px;
  border-bottom: 1px solid #e4f2ff;
  padding: 0 30px;
  padding-bottom: 15px;
}
.crancy-sidebarmenu__style--colors {
  display: flex;
  gap: 12px;
  padding: 0 30px;
}
.crancy-sidebarmenu__style--colors a {
  width: 38px;
  height: 38px;
  border-radius: 4px;
}
.crancy-sidebarmenu__style {
  margin-bottom: 22px;
}
.crancy-sidebarmenu__option--rtl .crancy-sidebarmenu__option--icon {
  background: #d4efdf;
}
.crancy-sidebarmenu__support {
  padding: 0 30px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-sidebarmenu__switcher {
  width: 75px;
  height: 30px;
  background: #fffdf8;
  border: 1px solid #e4f2ff;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
}
.crancy-sidebarmenu__switcher::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 24px;
  height: 24px;
  background: #2f3032;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.crancy-sidebarmenu__switcher.active::before {
  left: 60%;
  background: #0a82fd;
  transition: all 0.3s ease;
}
.crancy-sidebarmenu .crancy-balance__title,
.crancy-sidebarmenu .crancy-chatbox__author-title a,
.crancy-sidebarmenu__title,
.crancy-sidebarmenu__option--title,
.crancy-sidebarmenu__style--title,
.crancy-sidebarmenu .crancy-balance-name {
  color: #2f3032 !important;
}
.crancy-sidebarmenu .crancy-chatbox__author-title a:hover,
.crancy-sidebarmenu .crancy-balance-name:hover {
  color: #0a82fd !important;
}
.crancy-sidebarmenu .crancy-balance__title,
.crancy-sidebarmenu .crancy-balance_list li {
  border-bottom-color: #e4f2ff !important;
}
@media only screen and (min-width: 0px) and (max-width: 992px) {
  .crancy-sidebarmenu {
    display: none;
  }
}

.crancy-audience {
  background: #ebf5ff;
  padding: 9px 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dotted #bcddff;
}
.crancy-audience__single--title {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.crancy-audience__single--title span {
  font-size: 14px;
  color: #9aa2b1;
  font-weight: 400;
}
.crancy-audience__single.crancy-audience__single--middle {
  width: 1px;
  min-height: 50px;
  background: #bcddff;
  margin-left: -0.5px;
}
.crancy-history.crancy-history__v2 .crancy-history__text {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e4f2ff;
  justify-content: start;
}
.crancy-history.crancy-history__v2 .crancy-history__amount {
  display: flex;
  align-items: center;
  gap: 5px;
}
.crancy-history__prevday b {
  font-weight: 400;
  color: #2f3032;
}
.crancy-history.crancy-history__v2 .crancy-history__icon {
  border-radius: ;
  border-radius: 6px;
}
.crancy-single__box {
  background: #fff;
  border-radius: 12px;
  padding: 20px 25px;
}
.crancy-chatbox__list.crancy-chatbox__list--v3 li {
  padding: 0;
}
.crancy-chatbox__list.crancy-chatbox__list--v3 .crancy-chatbox__inner {
  padding-bottom: 15px;
  padding-top: 15px;
}
.crancy-chatbox__list.crancy-chatbox__list--v3 .crancy-chatbox__inner:hover {
  padding-left: 10px;
  padding-right: 10px;
}
.crancy-chatbox__sidebar.crancy-chatbox__sidebar--v3 {
  padding: 0;
}
.crancy-chatbox__sidebar.crancy-chatbox__sidebar--v3
  .crancy-chatbox__author-title {
  font-size: 18px;
}
.crancy-chatbox__sidebar.crancy-chatbox__sidebar--v3 .crancy-chatbox__list li {
  padding-left: 0;
  padding: 0;
}
.crancy-chatbox__sidebar.crancy-chatbox__sidebar--v3
  .crancy-chatbox__first-group {
  padding: 0;
}
.crancy-chatbox__sidebar.crancy-chatbox__sidebar--v3 img {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
}
.crancy-chatbox__sidebar.crancy-chatbox__sidebar--v3 .crancy-chatbox__inner {
  padding-top: 14px;
  padding-bottom: 14px;
}
.crancy-email-status__single {
  width: 204px;
  height: 204px;
  border-radius: 100%;
  background: #0a82fd;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  position: absolute;
}
.crancy-email-status {
  height: 250px;
  width: 100%;
  position: relative;
}
.crancy-email-status__single.crancy-email-status__single--promotion {
  width: 142px;
  height: 142px;
  right: 22%;
  background: #f2c94c;
  color: #2f3032;
  font-size: 26px;
  z-index: 33;
}
.crancy-email-status__single.crancy-email-status__single--forum {
  width: 115px;
  height: 115px;
  z-index: 33;
  background: #ef5da8;
  bottom: 8%;
  right: 24%;
  z-index: 3;
  font-size: 18px;
}
.crancy-email-status__single.crancy-email-status__single--social {
  width: 62px;
  height: 62px;
  bottom: 10%;
  left: 28%;
  font-size: 16px;
  font-weight: 400;
  background: #27ae60;
  z-index: 33;
}
.crancy-email-status__single.crancy-email-status__single--span {
  width: 80px;
  height: 80px;
  right: 10%;
  bottom: 21%;
  z-index: 3333;
  font-size: 16px;
  background: #9b51e0;
}
.crancy-see__all {
  font-weight: 400;
  color: #2f3032;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.crancy-see__all a {
  font-size: 16px;
  font-weight: 400;
  color: #0a82fd;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: underline;
}
.crancy-see__all svg {
  fill: #0a82fd;
}
.crancy-email-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.crancy-email-list li {
  padding-left: 30px;
}
.crancy-email-list__single {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.crancy-email-list__single:before {
  content: "";
  width: 16px;
  height: 16px;
  background: #0a82fd;
  display: inline-block;
  border-radius: 100%;
}
.crancy-email-list__single span {
  color: #9aa2b1;
}
.crancy-email-list__single.crancy-email-list__single--promotion::before {
  background: #f2c94c;
}
.crancy-email-list__single.crancy-email-list__single--forum::before {
  background: #ef5da8;
}
.crancy-email-list__single.crancy-email-list__single--social::before {
  background: #9b51e0;
}
.crancy-email-list__single.crancy-email-list__single--spam::before {
  background: #27ae60;
}
/* 404 Error */
.crancy-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  gap: 0;
  padding-bottom: 100px;
}
.crancy-error__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  transform: translateY(-80px);
  margin-bottom: -80px;
}
.crancy-error__title {
  font-size: 32px;
  margin: 0;
  font-weight: 600;
}
.error-img {
  width: 100%;
}
.crancy-btn.crancy-btn--error {
  padding: 11px 40px;
  font-size: 14px;
}
.crancy-btn.crancy-btn--error:hover {
  background-color: #2f3032;
}
/* Coming Soon */
.crancy-error__comming .crancy-error__text {
  color: #9aa2b1;
  letter-spacing: initial;
}
#crancy_countdown {
  display: flex;
  gap: 30px;
}
.crancy_countodwn-single {
  border: 1px solid #0a82fd;
  border-radius: 5px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9aa2b1;
  font-size: 18px;
  min-width: 105px;
  min-height: 105px;
}
.crancy_countodwn-single span {
  color: #2f3032;
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
}
@media only screen and (min-width: 0px) and (max-width: 1550px) {
  .crancy-error__content {
    gap: 25px;
    transform: translateY(-50px);
    margin-bottom: -50px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 1100px) {
  .crancy-error__content {
    transform: translateY(0);
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .crancy-sidebarmenu {
    width: 360px;
  }
  .crancy-email-status__single.crancy-email-status__single--promotion {
    right: 15%;
  }
  .error-img {
    width: 100%;
    max-height: 550px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1278px) {
  .crancy-email-status__single.crancy-email-status__single--promotion {
    right: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .crancy-error__title {
    font-size: 20px;
    line-height: initial;
  }
  .crancy-error__text {
    font-size: 16px;
  }
  #crancy_countdown {
    gap: 10px;
  }
  .crancy_countodwn-single {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  }
  .crancy_countodwn-single span {
    font-size: 35px;
  }
  .crancy_countodwn-single {
    font-size: 16px;
  }
  .crancy-single__box {
    min-height: initial;
  }
  .crancy-email-status__single.crancy-email-status__single--promotion {
    right: 0%;
  }
  .crancy-error {
    justify-content: start;
  }
}

/* Widget CSS */
.crancy-buttons-widget {
  margin-left: -15px;
}
.crancy-buttons-widget .cranncy-btn {
  margin-left: 15px;
}
.crancy-buttons-widget .crancy-btn {
  margin-left: 15px;
  margin-top: 15px;
}

/* Nice Select */
.nice-select,
select,
.crancy-table-filter__single input {
  background: #f7fafc !important;
  border: none;
  border-radius: 12px !important;
  font-weight: 400;
  font-size: 16px;
  color: #9aa2b1;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100% !important;
  min-height: 56px !important;
  padding: 0 20px;
  border: none !important;
  margin: 0;
}
.crancy-table-filter__group span {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -13px;
}
.crancy-table-filter__group {
  position: relative;
}
.crancy-table-filter__single {
  display: flex;
  flex-direction: column;
}
.nice-select::after {
  border-bottom-color: #0a82fd;
  border-right-color: #0a82fd;
  right: 35px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
}
.nice-select .list {
  width: 100%;
}
.nice-select .option {
  font-weight: 400 !important;
}
.nice-select .list {
  border: 1px solid #edf2f7 !important;
  box-shadow: none !important;
}
.nice-select .option:hover {
  background: #0a82fd !important;
}
.crancy-table-filter__single label {
  font-weight: 500;
  color: #2f3032;
}

/* Chart JS */
.crancy-chart__inside.crancy-chart__homev2 {
  max-height: 80px;
}

.crancy-history__graph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.crancy-history__main {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
}

.crancy-single-integration {
  background: #ffffff;
  padding: 25px;
  border-radius: 8px;
  position: relative;
  /* min-height: 320px; */
}
.crancy-single-integration__title {
  font-weight: 600;
  font-size: 24px;
  color: #2f3032;
  margin: 0;
}
.crancy-single-integration__label {
  color: #9aa2b1;
  font-weight: 400;
  font-size: 18px;
}
.crancy-single-integration__head {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 90%;
}
.crancy-toggle {
  position: absolute;
  right: 20px;
  top: 20px;
}

.crancy-single-integration__content {
  margin-top: 25px;
}
.crancy-btn.crancy-btn__regular {
  margin-top: 30px;
  border: 1px solid #9aa2b1;
  border-radius: 6px;
  background: transparent;
  color: #9aa2b1;
  font-size: 16px;
  font-weight: 400;
  display: block;
  padding: 10px 20px;
}
.crancy-btn.crancy-btn__regular.crancy-btn__regular--connect {
  border-color: #194bfb;
  color: #194bfb;
}
.crancy-single-integration__icon {
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-single-integration__icon img {
  max-width: 50px;
}

@media only screen and (max-width: 767px) {
  .crancy-single-integration__title {
    font-size: 20px;
  }
}

.crancy-single-user {
  background: #ffffff;
  border-radius: 10px;
  padding: 60px 20px 20px;
  text-align: center;
  border: 1px solid transparent;
}
.crancy-single-user:hover {
  border-color: #194bfb;
}
.crancy-single-user__title {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 0;
}
.crancy-single-user__title span {
  color: #9aa2b1;
  font-weight: 400;
  font-size: 14px;
}
.crancy-single-user__head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.crancy-single-user__label {
  background: #e8edff;
  border-radius: 6px;
  padding: 5px 12px;
  color: #194bfb;
  margin-top: 10px;
}
.crancy-single-user__info {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #f2f6ff;
}
.crancy-single-user__list {
  display: flex;
  flex-direction: column;
}
.crancy-single-user__list li {
  font-weight: 400;
  font-size: 14px;
  color: #2f3032;
  display: flex;
  justify-content: space-between;
}
.crancy-single-user__list li a,
.crancy-single-user__list li span {
  color: #9aa2b1;
  font-size: 14px;
  font-weight: 400;
}
.crancy-btn__default {
  border: 1px solid #9aa2b1;
  border-radius: 8px;
  color: #9aa2b1;
  background: transparent;
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  font-weight: 400;
  padding: 8px 44px;
}
.crancy-btn__default:hover {
  background-color: #194bfb;
  border-color: transparent;
  color: #fff;
}

/* Featurd Author */
.crancy-featured-user {
  background: #fff;
  padding: 0 50px 50px;
  border-radius: 10px;
}
.crancy-featured-user__head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  top: -40px;
  margin-bottom: -40px;
  padding: 0 30px;
}
.crancy-featured-user__title {
  font-weight: 600;
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.crancy-featured-user__text {
  font-weight: 400;
  font-size: 16px;
  color: #9aa2b1;
}
.crancy-featured-user__head img {
  max-width: 80px;
}
.crancy-featured-user__btn-group a:hover {
  border-color: #194bfb;
}
.crancy-featured-user__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.crancy-featured-user__list li {
  display: flex;
  justify-content: space-between;
  color: #9aa2b1;
  font-size: 16px;
}
.crancy-featured-user__list li a {
  color: #0f172a;
  font-weight: 500;
}
.crancy-featured-user__title--small {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
.crancy-featured-user__border {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #e2e8f0;
}
.crancy-featured-user__files a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e8edff;
  padding: 12px;
  border-radius: 8px;
}
.crancy-featured-user__fcontent {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy-featured-user__ficon {
  width: 42px;
  height: 42px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.crancy-featured-user__fname {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.crancy-featured-user__fsize {
  color: #9aa2b1;
  font-weight: 400;
}
.crancy-featured-user__hcard {
  display: flex;
  align-items: center;
  gap: 10px;
}

.crancy-featured-user__hagent--title {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}
.crancy-featured-user__hagent--title span {
  color: #9aa2b1;
  font-weight: 400;
}
.crancy-featured-user__hagent--title span b {
  color: #194bfb;
  font-weight: 400;
}
/* .crancy-btn.crancy-featured-user__btn{
	padding: 14px 30px;
} */

.crancy-featured-user__btn-group {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 25px;
}
.crancy-featured-user__btn-group a {
  width: 40px;
  height: 40px;
  line-height: ;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e8f0;
  border-radius: 100%;
}

.crancy-featured-user__more {
  color: #9aa2b1;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
}
.crancy-featured-user__lists {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.crancy-featured-user__lists span {
  background: #f8fafc;
  font-weight: 700;
  color: #9aa2b1;
}
.crancy-featured-user__button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Project Author */
.crancy-projects-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crancy-projects-head__heading {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}
.crancy-projects-head__right {
  display: flex;
  align-items: center;
  gap: 15px;
}
.crancy-projects-head__button {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crancy-projects-head__button.crancy-projects-head__button--filter {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 10px;
}

/* Category Tabs */
.crancy-pcats__list.crancy-prcats__list {
  border-bottom: 1px solid #d8e3f8;
  border-radius: 0px;
  display: flex !important;
  flex-wrap: wrap !important;
}
.crancy-pcats__list.crancy-prcats__list a {
  background: transparent !important;
  font-size: 16px !important;
  color: #9aa2b1;
  padding: 0 0 15px;
  display: flex;
  gap: 10px;
  margin: 0;
}
.crancy-pcats__list.crancy-prcats__list a span {
  background: #d8e3f8;
  padding: 2px 8px;
  border-radius: 30px;
  font-size: 14px;
  color: #9aa2b1 !important;
}
.crancy-pcats__list.crancy-prcats__list a.active,
.crancy-pcats__list.crancy-prcats__list a:hover {
  color: #00020b !important;
}
.crancy-pcats__list.crancy-prcats__list a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #0a82fd !important;
  opacity: 0;
  visibility: hidden;
}
.crancy-pcats__list.crancy-prcats__list a.active::before,
.crancy-pcats__list.crancy-prcats__list a:hover:before {
  opacity: 1 !important;
  visibility: visible !important;
}

.crancy-pcats__list.crancy-prcats__list {
  gap: 24px;
}

/* Project Progress */
.crancy-single-project__progress {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  color: #9aa2b1;
  margin-bottom: 5px;
  margin-top: 20px;
}
.crancy-single-project__percent {
  font-weight: 400;
  color: #0a82fd;
}
.crancy-single-project__head {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.crancy-single-project__icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e9eb;
  border-radius: 15px;
  background: #fff;
}
.crancy-single-project__icon img {
  max-width: 30px;
}
.crancy-single-project__title {
  color: #2f3032;
  font-weight: 700;
  margin: 0;
}
.crancy-single-project__label {
  font-size: 14px;
  font-weight: 500;
  color: #9aa2b1;
}
.crancy-single-project__content p {
  color: #9aa2b1;
}
.crancy-single-project {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
}

.crancy-single-project__bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crancy-single-project__date {
  background: #e8edff;
  border-radius: 8px;
  padding: 3px 18px;
  color: #0a82fd;
  display: flex;
  align-items: center;
  gap: 5px;
}

.crancy-user-search {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 15px;
  gap: 30px;
  flex-wrap: wrap;
}
.crancy-user-search__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crancy-user-search__single {
  border-radius: 0;
  padding-right: 30px;
  margin-right: 30px;
}
.crancy-user-search__single:last-child {
  margin: 0;
  padding: 0;
}
.crancy-user-search__single::after {
  content: "";
  width: 2px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px;
  background-color: #e2e8f0;
  top: 50%;
  margin-top: -15px;
}
.crancy-user-search__single:last-child:after {
  display: none;
}
.crancy-user-search__single {
  width: 24%;
}
.crancy-user-search__single.crancy-user-search__single--sform {
  width: 40%;
}
.crancy-header__form--user .crancy-header__form-inner {
  width: auto;
  background: transparent;
  padding: 0;
}
.crancy-user-search__single input,
.crancy-user-search__single select {
  font-size: 16px !important;
  font-weight: 400;
  color: #9aa2b1;
}

.crancy-header__form .crancy-header__form-inner input::-moz-placeholder {
  color: #9aa2b1 !important;
  opacity: 1 !important;
}
.crancy-header__form
  .crancy-header__form-inner
  input::-webkit-input-placeholder {
  color: #9aa2b1 !important;
  opacity: 1 !important;
}
.crancy-header__form .crancy-header__form-inner input:-ms-input-placeholder {
  color: #9aa2b1 !important;
  opacity: 1 !important;
}

.crancy-header__form--user .crancy-header__form-inner input,
.crancy-user-search__single--location select {
  background: transparent !important;
}
.crancy-user-search__single.crancy-user-search__single--location {
  display: flex;
  align-items: center;
  gap: 15px;
}
.crancy-user-search__single--location select {
  padding: 0;
}
.crancy-btn.crancy-btn__user {
  width: 155px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
}

.crancy-table__main-v3 .crancy-table__head {
  background: transparent;
}
.crancy-table__main-v3 .crancy-table__head th {
  border-top: 1px solid #e4f2ff;
  border-bottom: 1px solid #e4f2ff;
}

.crancy-table__customer-img {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0;
}
.crancy-table__customer-img img {
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.crancy-table__main.crancy-table__main-v3 tr th,
.crancy-table__main.crancy-table__main-v3 tr td {
  width: auto !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.crancy-table__actions {
  display: flex;
  align-items: center;
  gap: 20px;
}
.crancy-table__actions--between {
  justify-content: space-between;
}

.crancy-table__main.crancy-table__main-v3 .crancy-wc__checkbox {
  gap: 30px;
}
.crancy-table__main.crancy-table__main-v3 th {
  color: #9aa2b1 !important;
}
.crancy-customer-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  gap: 15px;
  flex-wrap: wrap;
}
.crancy-customer-filter__single--button {
  background: #fafafa;
  color: #9aa2b1;
  font-weight: 500;
  padding: 16px 33px;
  display: inline-block;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}
.crancy-header__form.crancy-header__form--customer .crancy-header__form-inner {
  /* min-height: 56px; */
  border-radius: 12px;
  background: #fafafa;
}
.crancy-table.crancy-table--v3 {
  padding: 30px 0;
}
.crancy-table.crancy-table--v3 .crancy-table-bottom {
  padding: 0 30px;
}

.crancy-customer-filter__single.crancy-customer-filter__single--csearch {
  min-width: 40%;
}
.crancy-header__form.crancy-header__form--customer .crancy-header__form-inner {
  width: 100%;
}
.charts-main-dates {
  background: transparent;
  border: 1px solid #e8e9eb;
  border-radius: 8px;
  padding: 0 10px;
  color: #2f3032;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 120px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.charts-main-dates__input {
  border: none !important;
  background: transparent !important;
  color: #2f3032 !important;
  cursor: pointer;
  font-size: 14px;
}
.charts-main-dates__input::-moz-placeholder {
  color: #2f3032 !important;
  opacity: 1 !important;
}
.charts-main-dates__input::-webkit-input-placeholder {
  color: #2f3032 !important;
  opacity: 1 !important;
}
.charts-main-dates__input:-ms-input-placeholder {
  color: #2f3032 !important;
  opacity: 1 !important;
}
.charts-main-dates__input::-ms-input-placeholder {
  color: #2f3032 !important;
  opacity: 1 !important;
}

.crancy-header__form.crancy-header__form--chatbox {
  width: 100%;
  padding: 0 25px;
}
.crancy-header__form.crancy-header__form--inbox {
  width: 100%;
}
.crancy-header__form.crancy-header__form--chatbox input {
  height: 60px;
}
.crancy-main-form__contact .crancy-main-form__group input,
.crancy-main-form__contact .crancy-main-form__group textarea {
  background: #f6f9ff;
  border: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}
.crancy-teams__title {
  font-size: 32px;
  font-weight: 700;
}
.crancy-table__inner--title a {
  text-decoration: underline;
}
.support-tracker-box {
  background: #fff;
  padding: 25px 40px;
  border-radius: 12px;
}
.crancy-pcats__list--inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}
.crancy-pcats__list.crancy-pcats__list--support {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #e8edff;
  border-radius: 8px;
  gap: 2px;
}
.crancy-pcats__list.crancy-pcats__list--support a {
  font-weight: 500;
  padding: 12px 22px;
  background: transparent;
  font-size: 14px;
  color: #2f3032;
}
.support-tracker-box__title {
  margin: 0;
}
.crancy-chatbox__new-message.crancy-chatbox__new-message__ticket {
  padding: 0 !important;
  margin: 50px 0 0 !important;
}
.crancy-table__main.crancy-table__ticket .crancy-table__head {
  background: #f3f9ff;
}

.crancy-user-search__single--location .nice-select {
  background: transparent !important;
  padding: 0;
}
.crancy-user-search__single--location .nice-select::after {
  right: 0;
  top: 50%;
  margin-top: -5px;
  border-bottom-color: #9aa2b1;
  border-right-color: #9aa2b1;
}

.crancy-chart__activity.crancy-chart__activity--v2 {
  height: 230px;
}
.crancy-progress-list.crancy-progress-list__initial p {
  justify-content: initial;
}
.crancy-uactivity.crancy-uactivity--v2 {
  min-height: 435px;
}
.crancy-ptabs__form-main .crancy__item-group {
  display: flex;
  flex-direction: column;
}
.crancy-ptabs .crancy__item-input {
  padding: 0 15px !important;
  height: 50px;
  background: #fafafa !important;
  border: 1px solid #e4f2ff !important;
  resize: none;
  border-radius: 5px !important;
}
.crancy-paymentm__icon.crancy-paymentm__icon--notify img {
  max-width: 100%;
}

@media only screen and (max-width: 1550px) {
  .crancy-table__title,
  .charts-main__title,
  .crancy-progress-card__title,
  .crancy-sidebar__title,
  .crancy-default-cd__event .fc-header-toolbar .fc-toolbar-title,
  .crancy__notification__title,
  .crancy-teams__title,
  .crancy__item-group__title,
  .crancy-ptabs__stitle,
  .crancy-default-cd .fc-toolbar-title {
    font-size: 20px !important;
  }
  .crancy-single-integration__title {
    font-size: 20px;
  }
  .crancy-user-search__single.crancy-user-search__single--sform {
    width: 34%;
  }
  .crancy-user-search__single {
    padding-right: 10px;
    margin-right: 10px;
    width: 27%;
  }
  .crancy-user-search__single:after {
    display: none;
  }
  .crancy-featured-user {
    padding: 0 20px 20px;
  }
  .crancy-featured-user__head {
    padding: 0 20px;
  }
  .crancy-featured-user__title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .crancy-table-filter .col-lg-3 {
    margin-top: 20px;
  }
  .crancy-table-filter .col-lg-3:first-child {
    margin: 0;
  }
  .crancy-chatbox__new-message {
    margin: 25px;
  }
  .crancy-chatbox__button {
    position: relative;
    flex-direction: column;
  }
  .crancy-chatbox__main-content {
    flex-direction: column;
  }
  .crancy-teams__title {
    font-size: 24px;
  }
  .crancy-team-meta {
    margin-top: 15px;
  }
  .crancy-cta__content {
    max-width: 100%;
  }
  .crancy-header__form.crancy-cta__search form {
    width: 100%;
  }

  .crancy-customer-filter__single,
  .crancy-customer-filter__single--button {
    width: 100%;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .crancy-to-dolist .col-lg-4 {
    margin-top: 30px;
  }
  .crancy-user-search__single {
    width: 100% !important;
  }
  .crancy-user-search.mg-top-40 {
    gap: 0;
  }
  .crancy-user-search__group {
    justify-content: center;
    gap: 30px;
  }
}

.crancy-pagination.crancy-pagination__v3 ul li.active a,
.crancy-pagination.crancy-pagination__v3 ul li:hover a {
  background-color: #0a82fd !important;
  color: #fff !important;
}
.crancy-alarm__hover--inner {
  overflow-x: scroll;
  max-height: 600px;
  scrollbar-width: none;
}
.crancy-alarm__hover--inner::-webkit-scrollbar {
  display: none;
}

.crancy-history.crancy-history--ticket .crancy-history__main {
  padding: 0;
}

@media only screen and (max-width: 1400px) {
  .crancy-alarm__hover--inner {
    max-height: 400px;
  }
}
@media only screen and (max-width: 1024px) {
  .crancy-balance {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .crancy-achievement__single {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
  .crancy-achievement {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .crancy-userprofile .crancy-userprofile__info-text {
    padding-right: 0;
  }
  .crancy-teams {
    padding: 20px;
  }
}

.crancy-pbreadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crancy-pbreadcrumb__list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crancy-pbreadcrumb__list a {
  color: #2f3032;
  font-size: 14px;
  font-weight: 400;
}
.crancy-pbreadcrumb__list li {
  color: red;
}
.crancy-pbreadcrumb__list li:after {
  content: "/";
  color: #2f3032;
  padding-left: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "/";
  font-size: 14px;
}
.crancy-pbreadcrumb__list li:last-child:after {
  display: none;
}
.crancy-pbreadcrumb__list li.active a,
.crancy-pbreadcrumb__list li:hover a {
  color: #0a82fd;
}
.crancy-pbreadcrumb__list li:hover a {
  opacity: 0.8;
}
.charts-main__middle.charts-main__middle--space {
  justify-content: center;
}
.charts-main__middle.charts-main__middle--space
  .crancy-progress-list.crancy-progress-list__inline {
  justify-content: center;
}
.crancy-wc__button button:hover {
  background: #2f3042 !important;
}
.crancy-wc__check-inline label a:hover,
.crancy-wc__text a:hover,
.forgot-pass:hover {
  opacity: 0.8;
}
.crancy__item-button--group--fix {
  gap: 30px;
}

@media only screen and (max-width: 767px) {
  .charts-main__middle.charts-main__middle--space
    .crancy-progress-list.crancy-progress-list__inline {
    gap: 15px;
  }
  .crancy-pcats__bar.crancy-pcats__bar--v2 .crancy-pcats__list {
    text-align: center;
  }
  .crancy-pcats__bar.crancy-pcats__bar--v2 a {
    font-size: 13px;
    padding: 10px 7px !important;
  }
  .mg-top-20-rs {
    margin-top: 20px !important;
  }
  .crancy-pbreadcrumb {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
  .crancy__item-button--group--fix {
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }
}
/*======================================
	End Utilities CSS
========================================*/

.crancy-close .menu-Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CircularProgressbar-text {
  font-weight: 700;
  line-height: initial;
}

.jvm-tooltip {
  display: none !important;
}

/* slideBtn  */
.slideBtn:checked::before {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #fff;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 10px;
}
/* switch-btn */
.notification-switch {
  text-align: center;
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 9999px;
  border: 2px solid transparent;
  transition-timing-function: ease-in-out;
  transition-duration: 400ms;
  height: 20px;
  width: 41px;
  background: #9aa2b1 !important;
}

.notification-switch-active {
  background: #27ae60 !important;
}

.notification-switch:focus {
  outline: none;
}

.notification-switch span {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  pointer-events: none;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: white;
  transition-timing-function: ease-in-out;
  transition-duration: 400ms;
}

.notification-switch-active span {
  transform: translateX(1.25rem);
}

.modal-bg {
  background: rgba(255, 255, 255, 0.5);
}

/* select box  */
.my-select-box {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  border-radius: 12px;
}
.my-select-box .click-away {
  width: 100%;
  height: 100%;
  z-index: 99;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
}
.my-select-box .my-select-box-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #f7fafc;
  border: none;
  font-weight: 400;
  font-size: 16px;
  color: #9aa2b1;
  display: flex;
  align-items: center;
  min-height: 45px;
  padding: 0 20px;
  border: none;
  margin: 0;
  border-radius: 12px;
}

.my-select-box-btn i {
  width: 24px;
  color: #0a82fd;
}

.my-select-box .my-select-box-section {
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgb(68 68 68 / 11%);
  box-sizing: border-box;
  padding: 0;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
}
.my-select-box .my-select-box-section.open {
  opacity: 1;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  visibility: visible;
}
.my-select-box .my-select-box-section ul {
  padding: 0;
}
.my-select-box .my-select-box-section li {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  font-size: 16px;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.my-select-box .my-select-box-section li:hover {
  background: #0a82fd;
  font-weight: 400;
  color: white;
}
.my-select-box .my-select-box-section li.selected {
  background: #0a82fd;
  font-weight: 400;
  color: white;
}
.rotate {
  transform: rotate(180deg);
}

.searchBarSelect .my-select-box-btn {
  background: transparent;
  padding: 0;
}
.searchBarSelect .my-select-box-btn i {
  width: 24px;
  color: #9aa2b1;
}

.f-active {
  display: block;
}

.fm-hover-animation {
  animation: menuShow 500ms;
}

@keyframes menuShow {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fm-hover-animation-2 {
  animation: menuShow2 500ms;
}
@keyframes menuShow2 {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(50px);
  }
}

.fm-dropdown {
  display: none;
  animation: dropdown 500ms;
}

.fm-dropdown-show {
  display: block;
}

@keyframes dropdown {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}

.fm_active_Link {
  color: red;
}
.imgUploadLabel{
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 20px;
  width: 10rem;
  height: 10rem;
  cursor: pointer;
  font-size: large;
}
.imgUploadLabel span{
  font-weight: lighter;
  font-size: small;
  padding-top: 0.5rem;
}

.imgUploadLabel input{
  display: none;
}
img{
  padding: 0;
  margin: 0;
}

.images{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image{
  margin: 1rem 0.5rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.image button{
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: #fff;
  background-color: lightcoral;
}
.image button{
  background-color: red;
}
.Toastify__toast-body{
  width: 100% !important;
}

/* ///////// calender ////////// */

/* .fc td, .fc th {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #aaa !important;
} */